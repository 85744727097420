import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Accordion,
  Breadcrumb,
  Row,
  Col,
  Button,
  Modal,
} from "react-bootstrap";
import NFT from "../assets/images/NFT.png";
import user from "../assets/images/user.png";
import check from "../assets/images/check.png";
import smalllogo from "../assets/images/small-logo.png";
import claimImg from "../assets/images/Group_446.svg";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { useParams } from "react-router-dom";
import { Pagination } from "react-pagination-bar";
import {
  handleGetHistory,
  handleGetNFTBidHistory,
  handleGetNftById,
  getAllAppUser,
  distributeNft,
} from "../shared/api";
import { API_URL, Node_BE_URL } from "../constant";
import axios from "axios";
import { toast } from "react-toastify";
import CustomLoader from "../Components/CustomeLoader";
import moment from "moment";
import { accountSelector } from "../redux/accountSlice";
import { useSelector } from "react-redux";
import Logo from "../assets/images/small-logo.png";
import { HeartCount } from "../Components/HeartCount";
import PaginationComponent from "./PaginationComponent";

function CollectionDetail() {
  const { account } = useSelector(accountSelector);

  const { id } = useParams();
  const navigate = useNavigate();
  const [nftData, setnftData] = useState();
  const [loader, setloader] = useState(true);
  const [showSellModal, setShowSellModal] = useState(false);
  const [showDistributeModal, setshowDistributeModal] = useState(false);
  const [sellingPrice, setSellingPrice] = useState("");
  const [sellingErr, setsellingErr] = useState();
  const [DistributionCopiesError, setDistributionCopiesError] = useState();
  const [isBIdModal, setisBIdModal] = useState(false);
  const [openBidErr, setopenBidErr] = useState("");
  const [nftHistory, setnftHistory] = useState([]);
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [isInterval, setIsInterval] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [bidHistory, setbidHistory] = useState([]);
  const [currentBidPage, setcurrentBidPage] = useState(1);
  const [bidpagetotal, setbidpagetotal] = useState(0);
  const [minCopies, setminCopies] = useState(0);
  const [maxCopies, setmaxCopies] = useState(0);
  const [options, setOptions] = useState([]);
  const [startDate, setStartDate] = useState(
    // new Date().toISOString().slice(0, 10)
    null
  );
  const [endDate, setEndDate] = useState(null);
  const PropertyType = {
    Currency: "Currency",
  };
  const NFT_TYPE = {
    MarketPlace: "MarketPlace",
    Product: "Product",
    Tier: "Tier",
    promotion: "promotion",
  };
  useEffect(() => {
    setloader(true);
    getNftData();
  }, []);
  /**
   * Fetches all location data from the API and updates the state with the response.
   * The `useEffect` hook calls this function when the component mounts to load locations initially.
   */
  useEffect(() => {
    GetAllLocations();
  }, [nftData]);

  const GetAllLocations = () => {
    axios
      .get(`${Node_BE_URL}/api/admin/getAllLocations`)
      .then((data) => {
        const formattedOptions = data?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions(formattedOptions);
      })
      .catch((err) => {});
  };
  const getNftData = () => {
    handleGetNftById(id)
      .then((res) => {
        setnftData(res?.data?.data);
        setloader(false);
      })
      .catch((error) => {
        setloader(false);
      });
    handleGetHistory(currentPage, id).then((res) => {
      setnftHistory(res.data.data);
    });
    handleGetNFTBidHistory(id, currentBidPage).then((res) => {
      setbidHistory(res.data.data);
      setcurrentBidPage(res.data.totalItems);
    });
  };

  function handleStartDateChange(event) {
    const date = event.target.value;

    // if (date < endDate) {
    setStartDate(date);
    // }
  }

  function handleEndDateChange(event) {
    const date = event.target.value;
    // if (date > startDate) {
    setEndDate(date);
    // }
  }

  const handleClose = () => setShowSellModal(false);
  const handleShow = () => setShowSellModal(true);
  const handleCloseDistributeModal = () => setshowDistributeModal(false);
  const handleShowDistributeModal = () => {
    setloader(true);
    const tireType = nftData?.tierType;

    getAllAppUser()
      .then((res) => {
        const matchingTire = res.data.data.find(
          (tire) =>
            tire.tireType === tireType || tire.tireType === "Owner's Circle"
        );
        if (matchingTire) {
          setminCopies(matchingTire.tireCount);
        }
        setloader(false);
        setshowDistributeModal(true);
      })
      .catch((error) => {
        toast.error("An error occured. Please try again.");
        setloader(false);
      });
  };
  const handlebidClose = () => {
    setopenBidErr("");
    setisBIdModal(false);
  };
  const handlebidShow = () => setisBIdModal(true);
  function handleSellingPriceChange(event) {
    const price = event.target.value;
    if (price === "" || (price >= 0 && /^\d+(\.\d{1,5})?$/.test(price))) {
      setSellingPrice(price);
      setDistributionCopiesError();
    }
    if (parseFloat(price) == 0) {
      setsellingErr("Price Can not be zero");
    } else {
      setsellingErr("");
    }
  }
  function handleDistributionCopies(event) {
    const copies = event.target.value;

    const sanitizedValue = copies.replace(/^0+/, "");

    // Validate the input format
    const checkDecimal = /^[1-9]\d*(\.\d{1,5})?$/;

    if (copies === "" || (copies >= 0 && checkDecimal.test(sanitizedValue))) {
      setmaxCopies(sanitizedValue);
      setDistributionCopiesError();
    }
    if (copies == 0 || copies < minCopies) {
      setDistributionCopiesError("Must be greater than min copies.");
    }
  }

  const handleSellNft = (id) => {
    if (sellingErr || !sellingPrice) {
      return;
    }
    setloader(true);

    const payload = {
      nftId: id,
      price: sellingPrice,
      approvalTransactionHash: "",
      openOrderTransactionHash: "",
    };
    axios
      .post(API_URL + `api/Nft/SellNftMarket`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        toast.success("NFT Sold Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        setloader(false);
      })
      .catch((error) => {
        setloader(false);
      });
  };
  const handleOpenBid = (id) => {
    const minPriceInput = document.getElementById("minPrice");
    const maxPriceInput = document.getElementById("maxPrice");
    let strtdateopenbid = moment(startDate);
    let enddateopenbid = moment(endDate);
    if (!startDate) {
      setopenBidErr("Start Date Required");
      return;
    } else if (!endDate) {
      setopenBidErr("End Date Required");
      return;
      // } else if (startDate == endDate) {
    } else if (strtdateopenbid.isSame(enddateopenbid)) {
      setopenBidErr("Date can not be same");
    } else if (enddateopenbid.isBefore(strtdateopenbid)) {
      setopenBidErr("End Date can not be smaller than Start Date");
    } else if (
      Number(minPriceInput.value) < 0 ||
      Number(maxPriceInput.value) < 0
    ) {
      setopenBidErr("Prince can not be negative");
    } else if (
      Number(minPriceInput.value == 0) ||
      Number(maxPriceInput.value) == 0
    ) {
      setopenBidErr("Price can not be zero");
    } else if (!minPriceInput.value) {
      setopenBidErr("Minimum Price Required ");
    } else if (!maxPriceInput.value) {
      setopenBidErr("Maximum Price Required");
    } else if (Number(minPriceInput.value) > Number(maxPriceInput.value)) {
      setopenBidErr("Minimum price must be less than maximum price");
      return;
    } else if (Number(minPriceInput.value) === Number(maxPriceInput.value)) {
      setopenBidErr("Minimum price and maximum price cannot be the same");
      return;
    } else {
      // sell the NFT
      setloader(true);
      const payload = {
        nftId: id,
        isBidOpen: true,
        maximumAmount: maxPriceInput.value,
        minimumAmount: minPriceInput.value,
        bidStartDate: startDate,
        bidEndDate: endDate,
      };
      axios
        .post(API_URL + `api/Nft/OpenBid`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
        })
        .then((res) => {
          toast.success("NFT Open bid is placed");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          setloader(false);
        })
        .catch((error) => {
          setloader(false);
        });
    }
  };
  const handleDistributeNft = (id) => {
    if (maxCopies < minCopies) {
      setDistributionCopiesError("Must be greater than min copies.");
      return;
    }
    handleCloseDistributeModal();
    setloader(true);
    distributeNft(id, maxCopies)
      .then((res) => {
        toast.success(res.data.data);

        handleCloseDistributeModal();
        getNftData();
      })
      .catch((error) => {
        setloader(false);
        toast.error("An error occured, please try again.");
        handleCloseDistributeModal();
      });
  };
  const handleCancellist = (id) => {
    setloader(true);
    const payload = {
      nftId: id,
      cancelTransactionHash: "",
    };
    axios
      .post(API_URL + `api/Nft/NftCancelStatus`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        toast.success("NFT De-listed Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        setloader(false);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (nftData) {
      const currentTime = moment.utc().unix();
      const endtime12 = moment(nftData?.bidEndDate).utc().unix();
      const diffTime = endtime12 - currentTime;
      let duration = moment.duration(diffTime * 1000, "milliseconds");
      const interval = 1000;
      var timerID = setInterval(() => {
        setIsInterval(true);
        if (duration._milliseconds <= 0) {
          setDays("0");
          setHours("0");
          setMinutes("0");
          setSeconds("0");
        } else {
          duration = moment.duration(duration - interval, "milliseconds");
          setDays(parseInt(duration.asDays()));
          setHours(duration.hours());
          setMinutes(duration.minutes());
          setSeconds(duration.seconds());
        }
      }, interval);
      return () => clearInterval(timerID);
    }
  }, [nftData]);
  const handleAcceptBid = (bidId) => {
    setloader(true);
    axios
      .put(
        API_URL + `api/Nft/bidAccepted?bidId=${bidId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
        }
      )
      .then((res) => {
        toast.success("Bid has been accepted");
        setloader(false);
        navigate(`/NFTCollectionManagement`);
      })
      .catch((error) => {
        setloader(false);
      });
  };

  const selectedStore = options?.find(
    (option) => option.value == nftData?.locationId
  );
  const storeName = selectedStore ? selectedStore?.label : "IK";
  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="/NFTCollectionManagement">
                  NFT Collection Management
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() =>
                    navigate(`/Collection/${nftData?.collectionId}`)
                  }
                  href="#"
                >
                  {nftData?.collectionName}
                </Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>

            <>
              <div className="full-div">
                <div className="full-div">
                  <h1>{nftData?.name}</h1>
                </div>
                <div className="spacer-20"></div>
              </div>
              <div
                className="collection-container"
                // style={{ maxWidth: "950px" }}
              >
                <div className="full-div">
                  <div className="collection-detail-container">
                    <div className="img-pnl">
                      {nftData?.distributionType == NFT_TYPE.Product ||
                      nftData?.distributionType == NFT_TYPE.Tier ? (
                        <>
                          <Row>
                            <Col xl="12" lg="6" md="6">
                              <div
                                style={{
                                  padding: 10,
                                  flexDirection: "column",
                                  display: "flex",
                                }}
                              >
                                <label
                                  className="font-bold"
                                  style={{
                                    fontSize: 20,
                                  }}
                                >
                                  Active Image
                                </label>
                                <div className="image-container">
                                  <img
                                    className="nft-image-p"
                                    src={`${API_URL}${nftData?.activeImage}`}
                                    alt="user"
                                  />
                                  <div className="heart-pnl-active">
                                    <HeartCount nftData={nftData} />
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xl="12" lg="6" md="6">
                              <div
                                style={{
                                  padding: 10,
                                  flexDirection: "column",
                                  display: "flex",
                                }}
                              >
                                <label
                                  className="font-bold"
                                  style={{
                                    fontSize: 20,
                                  }}
                                >
                                  Expired Image
                                </label>
                                <div className="image-container">
                                  <img
                                    className="nft-image-p"
                                    src={`${API_URL}${nftData?.expiredImage}`}
                                    alt="user"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xl="12" lg="6" md="6">
                              <div
                                style={{
                                  padding: 10,
                                  flexDirection: "column",
                                  display: "flex",
                                }}
                              >
                                <label
                                  className="font-bold"
                                  style={{
                                    fontSize: 20,
                                  }}
                                >
                                  Used Image
                                </label>

                                <div className="image-container">
                                  <img
                                    className="nft-image-p"
                                    src={`${API_URL}${nftData?.usedImage}`}
                                    alt="user"
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="heart-pnl">
                              <HeartCount nftData={nftData} />
                            </div>

                            <img
                              src={`${API_URL}${nftData?.image}`}
                              alt="NFT"
                            />
                          </div>
                        </>
                      )}

                      {/* <Link to="#" className="like-link">
                        <i className="fa fa-heart-o"></i>
                      </Link> */}
                      {/* if bid open */}
                      {nftData?.isBidOpen && (
                        <div className="timer-pnl">
                          {days ? days : 0}d {hours ? hours : 0}h{" "}
                          {minutes ? minutes : 0}m {seconds ? seconds : 0}s
                        </div>
                      )}
                      {nftData?.isBidOpen && (
                        <div className="heart-pnl">
                          <HeartCount nftData={nftData} />
                        </div>
                      )}

                      <div className="price-panel">
                        <div className="flex-div">
                          <p>
                            #
                            {`${nftData?.id.substring(
                              0,
                              4
                            )}...${nftData?.id.substring(
                              nftData?.id.length - 4
                            )}`}
                          </p>
                          <p>
                            <i className="fa fa-line-chart"></i>
                          </p>
                        </div>

                        <div className="flex-div">
                          {nftData?.isBidOpen ? (
                            <div
                              style={{ display: "block" }}
                              className="flex-div"
                            >
                              <p>
                                Min Price : {nftData?.bidInitialMinimumAmount}{" "}
                                <img
                                  className="Min-Prize"
                                  height={20}
                                  width={20}
                                  src={Logo}
                                  alt="logo"
                                />
                              </p>

                              <br />
                              <p>
                                Max Price : {nftData?.bidInitialMaximumAmount}{" "}
                                <img
                                  height={20}
                                  width={20}
                                  src={Logo}
                                  className="Min-Prize"
                                  alt="logo"
                                />
                              </p>
                            </div>
                          ) : (
                            nftData?.staus == "ReadyForSell" && (
                              <>
                                {nftData?.sellPrice ? (
                                  <p>
                                    Price: {nftData?.sellPrice}{" "}
                                    <img
                                      className="Min-Prize"
                                      height={20}
                                      width={20}
                                      src={Logo}
                                      alt="logo"
                                    />
                                  </p>
                                ) : (
                                  <p></p>
                                )}
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="txt-pnl">
                      <h2>{nftData?.name}</h2>
                      <h3>{nftData?.description}</h3>
                      <Row>
                        <Col md="6">
                          <Link to="#" className="creator-post">
                            <div className="creator-post-inner">
                              <div className="img-pnl">
                                <img src={user} alt="user" />
                              </div>
                              <div className="txt-pnl">
                                <h6>
                                  {account?.firstName} {account?.lastName}
                                </h6>
                                <p>0x330...8bc</p>
                              </div>
                            </div>
                            <div>
                              <img src={check} alt="checked" />
                            </div>
                          </Link>
                        </Col>
                        <Col md="6">
                          <Link
                            to={`/Collection/${nftData?.collectionId}`}
                            className="creator-post"
                          >
                            <div className="creator-post-inner">
                              <div className="img-pnl">
                                <img
                                  src={`${API_URL}${nftData?.logoImage}`}
                                  alt="user"
                                />
                              </div>
                              <div className="txt-pnl">
                                <h6
                                // style={{ marginLeft: "1%" }}
                                >
                                  {nftData?.collectionName}
                                </h6>
                              </div>
                            </div>
                            <div>
                              <img src={check} alt="checked" />
                            </div>
                          </Link>
                        </Col>

                        {(nftData?.distributionType == NFT_TYPE.Product ||
                          nftData?.distributionType == NFT_TYPE.Tier ||
                          nftData?.distributionType ==
                            NFT_TYPE.MarketPlace) && (
                          <>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">
                                  NFT Distribution Type
                                </label>
                                <p>{nftData?.distributionType}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">Expire Date</label>
                                <p>{nftData?.expiryDate.split("T")[0]}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">
                                  Total Copies
                                </label>
                                <p>{nftData?.totalNft}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">
                                  Remaining Copies
                                </label>
                                <p>{nftData?.noOfCopy}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">CompanyId </label>
                                <p>{nftData?.companyId ?? "--"}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">ProductId </label>
                                <p>{nftData?.productId ?? "--"}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">
                                  Product Name
                                </label>
                                <p>{nftData?.productName}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">GTIN Number</label>
                                <p>{nftData?.gtin ?? "--"}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="full-width">
                                <label className="font-bold">Store Name</label>
                                <p>{storeName}</p>{" "}
                                {/* Displays the store name or "IK" */}
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="full-width">
                              <label className="font-bold">
                                  Discount Code
                                </label>
                                <p>{nftData?.discountCode}</p>
                                {/* Displays the store name or "IK" */}
                              </div>
                            </Col>
                          </>
                        )}

                        {nftData?.distributionType == NFT_TYPE.Product && <></>}

                        {nftData?.distributionType == NFT_TYPE.Tier && (
                          <>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">Tier Type</label>
                                <p>{nftData?.tierType}</p>
                              </div>
                            </Col>
                            {/* <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">
                                  Discount Code
                                </label>
                                <p>{nftData?.discountCode}</p>
                              </div>
                            </Col> */}
                            <Col md="6">
                              <div className="full-width">
                                <label className="font-bold">Store Name</label>
                                <p>{storeName}</p>
                              </div>
                            </Col>
                          </>
                        )}
                        {/* {nftData?.distributionType == "Tier" && (
                          <Col md="6">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label className="font-bold">Tier Type</label>
                              <p>{nftData?.tierType}</p>
                            </div>
                          </Col>
                        )} */}
                        {nftData?.distributionType == "Promotion" && (
                          <>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">Product </label>
                                <p>{nftData?.productName}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">
                                  GTIN Number{" "}
                                </label>
                                <p>{nftData?.gtin ?? "--"}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">CompanyId </label>
                                <p>{nftData?.companyId ?? "--"}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">ProductId </label>
                                <p>{nftData?.productId ?? "--"}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">NFT Copies </label>
                                <p>{nftData?.totalNft}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">
                                  Remaning Copies{" "}
                                </label>
                                <p>{nftData?.noOfCopy}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">
                                  Expiry Date{" "}
                                </label>
                                <p>{nftData?.expiryDate.split("T")[0]}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label className="font-bold">
                                  Discount Code
                                </label>
                                <p>{nftData?.discountCode}</p>
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="full-width">
                                <label className="font-bold">Store Name</label>
                                <p>{storeName}</p>
                              </div>
                            </Col>
                          </>
                        )}

                        {nftData?.isNftDistributed && (
                          <Col md="12 mt-5">
                            <button
                              className="reg-btn big"
                              onClick={handleShowDistributeModal}
                            >
                              Distribute NFT's
                            </button>
                          </Col>
                        )}

                        {/* <Col md="12">
                          <div style={{
                            padding:10,flexDirection:"column",display:"flex"
                          }}
                          >
                            <label>abc</label>
                            <img style={{width:400,height:150}} src={`${API_URL}${nftData?.bannerImage}`} alt="user" />
                          </div>
                        </Col>
                        <Col md="12">
                          <div
                          style={{
                            padding:10,
                            lexDirection:"column",display:"flex"
                          }}
                          >
                            <img style={{width:400,height:150}} src={`${API_URL}${nftData?.bannerImage}`} alt="user" />
                          </div>                        
                          </Col>
                        <Col md="12">
                          <div
                          style={{
                            padding:10,
                            lexDirection:"column",display:"flex"
                          }}
                          >
                            <img style={{width:400,height:150}} src={`${API_URL}${nftData?.bannerImage}`} alt="user" />
                          </div>                        
                          </Col> */}
                      </Row>
                      {nftData?.isBidOpen && (
                        <>
                          <h4>Live Bid</h4>

                          <Row>
                            <Col md="12">
                              <div className="history-table">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Account</th>
                                      <th>Date</th>
                                      <th>Price</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {bidHistory?.map((item) => (
                                      <tr key={item.id}>
                                        <td>{item.accountId}</td>
                                        <td>{item.expiryDate.split("T")[0]}</td>
                                        <td>
                                          {item.price}{" "}
                                          <img
                                            height={20}
                                            width={20}
                                            className="Min-Prize"
                                            src={Logo}
                                            alt=""
                                          />{" "}
                                        </td>
                                        {nftData?.ownerAccountId ==
                                          account.accountId && (
                                          <td>
                                            <button
                                              style={{ minWidth: "100px" }}
                                              className="reg-btn big"
                                              onClick={() => {
                                                handleAcceptBid(item.id);
                                              }}
                                            >
                                              Accept
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </Col>
                            <Col md="12" className="pagination-container">
                              <PaginationComponent
                                totalItems={bidpagetotal}
                                itemsPerPage={10}
                                currentPage={currentBidPage}
                                onPageChange={(pageNumber) => {
                                  setcurrentBidPage(pageNumber);
                                }}
                                customClassNames={{
                                  rpbItemClassName: "custom-item",
                                  rpbItemClassNameActive: "custom-item--active",
                                  rpbGoItemClassName: "custom-go-item",
                                  rpbItemClassNameDisable:
                                    "custom-item--disable",
                                  rpbProgressClassName: "custom-progress-bar",
                                  rpbRootClassName: "custom-root",
                                }}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="full-div">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Properties <i className="fa fa-angle-down"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            {nftData?.nftProperties &&
                              nftData?.nftProperties.length === 0 && (
                                <span className="no-property">
                                  No Property Found.
                                </span>
                              )}
                            {nftData?.nftProperties &&
                              nftData?.nftProperties?.map((data, index) => {
                                return (
                                  <Col
                                    xs={6}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    className={
                                      "d-flex justify-content-center flex-column align-items-center mt-3 word-break-breakall"
                                    }
                                    key={index}
                                  >
                                    <div
                                      className="w-100"
                                      style={{
                                        backgroundColor:
                                          "rgba(21, 178, 229, 0.06)",
                                        borderRadius: 6,
                                        border: "1px solid #AD79F7",
                                        padding: "5px 5px",
                                        textAlign: "center",
                                        wordBreak: "break",
                                        marginBottom: "15px",
                                        color: "white",
                                        backgroundColor: "#AD79F7",
                                      }}
                                    >
                                      <p>{data.type}</p>
                                      <h4>
                                        <strong>{data.name} </strong>
                                      </h4>
                                      <p>
                                        {data.type !== PropertyType.Currency ? (
                                          <img
                                            height={20}
                                            width={20}
                                            className="Min-Prize"
                                            src={Logo}
                                            alt=""
                                          />
                                        ) : (
                                          "$"
                                        )}
                                        {data.rarity}
                                      </p>
                                    </div>
                                  </Col>
                                );
                              })}
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          Activity <i className="fa fa-angle-down"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="history-table">
                            <table className="w-100">
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>to</th>
                                  {/* <th>NFT Status</th>
                                <th>Created At</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {nftHistory?.map((item, index) => (
                                  <tr key={item.id}>
                                    {item.nftHistoryType == "Transferred" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <img
                                            src="/Transfer.png"
                                            height={30}
                                          />
                                        </div>

                                        <span className="table-span">
                                          {" "}
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}
                                    {item.nftHistoryType == "Distribute" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <img src="/sale.png" height={30} />
                                        </div>
                                        <span className="table-span">
                                          {" "}
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}
                                    {item.nftHistoryType == "Redeemed" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <img src="/redeem.png" height={30} />
                                        </div>
                                        <span className="table-span">
                                          {" "}
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}
                                    {item.nftHistoryType == "Claim" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <img
                                            src={claimImg}
                                            style={{ color: "purple" }}
                                            height={30}
                                          />
                                        </div>
                                        <span className="table-span">
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}
                                    {item.nftHistoryType == "Create" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <img src="/created.png" height={30} />
                                        </div>
                                        <span className="table-span">
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}
                                    {item.nftHistoryType == "List" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <img src="/List.png" height={30} />
                                        </div>
                                        <span className="table-span">
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}
                                    {item.nftHistoryType == "BidPlaced" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <img src="/List.png" height={30} />
                                        </div>
                                        <span className="table-span">
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}
                                    {item.nftHistoryType == "BidOpen" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <img src="/List.png" height={30} />
                                        </div>
                                        <span className="table-span">
                                          {" "}
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}
                                    {item.nftHistoryType == "AcceptBid" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <ArrowsUpDownIcon />
                                        </div>
                                        <span className="table-span">
                                          {" "}
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}

                                    {item.nftHistoryType == "Cancel" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <NoSymbolIcon />
                                        </div>
                                        <span className="table-span">
                                          Delist
                                        </span>
                                      </td>
                                    )}
                                    {item.nftHistoryType == "FromOpenSea" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <DocumentMagnifyingGlassIcon />
                                        </div>
                                        <span className="table-span">
                                          {" "}
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}
                                    {item.nftHistoryType == "sale" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <img src="/sale.png" height={30} />
                                        </div>
                                        <span className="table-span">
                                          {" "}
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}
                                     {item.nftHistoryType == "Send" && (
                                      <td className="table-td">
                                        <div className="image-container">
                                          <img src="/sale.png" height={30} />
                                        </div>
                                        <span className="table-span">
                                          {" "}
                                          {item.nftHistoryType}
                                        </span>
                                      </td>
                                    )}

                                    <td>{item.email}</td>
                                    {/* <td>{item.nftPrice}</td>
                                  <td>{item.nftHistoryType}</td>
                                  <td>{item.createdAt}</td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="pagination-container">
                            <PaginationComponent
                              totalItems={totalItems}
                              itemsPerPage={10}
                              currentPage={currentPage}
                              onPageChange={(pageNumber) => {
                                setCurrentPage(pageNumber);
                              }}
                              customClassNames={{
                                rpbItemClassName: "custom-item",
                                rpbItemClassNameActive: "custom-item--active",
                                rpbGoItemClassName: "custom-go-item",
                                rpbItemClassNameDisable: "custom-item--disable",
                                rpbProgressClassName: "custom-progress-bar",
                                rpbRootClassName: "custom-root",
                              }}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Transfers <i className="fa fa-angle-down"></i>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>Token id : {nftData?.nftTokenId}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>

                {/* sell nft modal */}

                <Modal centered show={showSellModal} onHide={handleClose}>
                  <Modal.Header>
                    <Button
                      closeButton
                      onClick={handleClose}
                      className="close-btn"
                    >
                      <i className="fa fa-close"></i>
                    </Button>
                    <Modal.Title>Sell NFT</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col md={12}>
                        <label htmlFor="sellingPrice">Selling Price:</label>
                      </Col>
                      <Col md={12}>
                        <input
                          style={{ width: "100%" }}
                          type="number"
                          id="sellingPrice"
                          name="sellingPrice"
                          placeholder="Enter Price to Sell nft"
                          min="0.00001"
                          step="0.00001"
                          value={sellingPrice}
                          className="form-control mb-3"
                          onChange={handleSellingPriceChange}
                        />
                        {sellingErr && (
                          <p className="error-msg">{sellingErr}</p>
                        )}
                      </Col>

                      <Col md={12}>
                        <div className="full-div text-center mt-3">
                          <Button
                            disabled={loader}
                            onClick={() => handleSellNft(nftData?.id)}
                            className="reg-btn big"
                          >
                            {" "}
                            Sell
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Modal>
                {/* distribute nft modal */}
                <Modal
                  centered
                  show={showDistributeModal}
                  onHide={handleCloseDistributeModal}
                >
                  <Modal.Header>
                    <Button
                      closeButton
                      onClick={handleCloseDistributeModal}
                      className="close-btn"
                    >
                      <i className="fa fa-close"></i>
                    </Button>
                    <Modal.Title>Distribute NFT's</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col md={6}>
                        <label htmlFor="sellingPrice">Min Copies:</label>
                        <input
                          type="number"
                          id="sellingPrice"
                          name="sellingPrice"
                          placeholder="Enter Price to Sell nft"
                          disabled
                          value={minCopies}
                          className="form-control mb-3"
                        />
                      </Col>

                      <Col md={6}>
                        <label htmlFor="sellingPrice">Max Copies:</label>
                        <input
                          type="number"
                          id="sellingPrice"
                          name="sellingPrice"
                          placeholder="Enter Price to Sell nft"
                          min={`${minCopies}`}
                          step="1"
                          value={maxCopies}
                          className="form-control mb-3"
                          onChange={handleDistributionCopies}
                        />
                        {DistributionCopiesError && (
                          <p className="error-msg">{DistributionCopiesError}</p>
                        )}
                      </Col>

                      <Col md={12}>
                        <div className="full-div text-center mt-3">
                          <Button
                            disabled={loader || DistributionCopiesError}
                            onClick={() => handleDistributeNft(nftData?.id)}
                            className={`reg-btn big ${
                              DistributionCopiesError && "reg-btn-disabled"
                            }`}
                          >
                            {" "}
                            Distribute
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Modal>
                {/* open bid modal */}
                <Modal centered show={isBIdModal} onHide={handlebidClose}>
                  <Modal.Header>
                    <Button
                      closeButton
                      className="close-btn"
                      onClick={handlebidClose}
                    >
                      <i className="fa fa-close"></i>
                    </Button>
                    <Modal.Title>Open Bid</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col md={6}>
                        <label style={{ width: "100%" }} htmlFor="startDate">
                          Start Date
                        </label>
                        <input
                          style={{ width: "100%", border: "1px solid #ccc" }}
                          type="date"
                          className="form-control mb-3"
                          name="startDate"
                          id="startDate"
                          value={startDate}
                          min={new Date().toISOString().slice(0, 10)}
                          onChange={handleStartDateChange}
                        />
                      </Col>
                      <Col md={6}>
                        <label style={{ width: "100%" }} htmlFor="endDate">
                          End Date
                        </label>
                        <input
                          style={{ width: "100%", border: "1px solid #ccc" }}
                          type="date"
                          name="endDate"
                          id="endDate"
                          className="form-control mb-3"
                          value={endDate}
                          min={startDate}
                          onChange={handleEndDateChange}
                        />
                      </Col>
                      <Col md={6}>
                        <label style={{ width: "100%" }} htmlFor="minPrice">
                          Minimum Price
                        </label>
                        <input
                          style={{ width: "100%" }}
                          type="number"
                          className="form-control mb-3"
                          name="minPrice"
                          id="minPrice"
                          placeholder="Enter price here"
                        />
                      </Col>
                      <Col md={6}>
                        <label style={{ width: "100%" }} htmlFor="maxPrice">
                          Maximum Price
                        </label>
                        <input
                          style={{ width: "100%" }}
                          type="number"
                          name="maxPrice"
                          className="form-control mb-3"
                          id="maxPrice"
                          placeholder="Enter price here"
                        />
                      </Col>

                      <Col md={12}>
                        {openBidErr && (
                          <p className="error-msg">{openBidErr}</p>
                        )}
                      </Col>
                      <Col md={12}>
                        <div className="full-div text-center mb-2 mt-3">
                          <Button
                            disabled={loader}
                            onClick={() => handleOpenBid(nftData?.id)}
                            className="reg-btn big"
                          >
                            {" "}
                            Open Bid
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Modal>

                {nftData?.distributionType == "Promotion" && (
                  <div className="full-div text-center">
                    <Row>
                      {nftData?.staus == "Hold" && !nftData?.isBidOpen && (
                        <>
                          {nftData?.distributionType === NFT_TYPE.Product &&
                            nftData?.distributionType === NFT_TYPE.Tier && (
                              <>
                                {nftData?.ownerAccountId ===
                                  account.accountId && (
                                  <Col md={6}>
                                    <Button
                                      onClick={handleShow}
                                      className="reg-btn big"
                                    >
                                      Sell NFT
                                    </Button>
                                  </Col>
                                )}
                                {nftData?.ownerAccountId ===
                                  account.accountId && (
                                  <Col md={6}>
                                    <Button
                                      onClick={handlebidShow}
                                      className="reg-btn big"
                                    >
                                      Open Bid
                                    </Button>
                                  </Col>
                                )}
                              </>
                            )}
                        </>
                      )}
                      {/* <Col md={12}>
                        {(nftData?.staus == "ReadyForSell" ||
                          nftData?.isBidOpen) && (
                            <>
                              {nftData?.ownerAccountId == account.accountId && (
                                <Button
                                  disabled={loader}
                                  onClick={() => handleCancellist(nftData?.id)}
                                  className="reg-btn big"
                                >
                                  De-List
                                </Button>
                              )}
                            </>
                          )}
                      </Col> */}
                    </Row>
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
      </main>
    </>
  );
}
export default CollectionDetail;
export function ArrowsUpDownIcon(props) {
  return (
    <svg
      fill="none"
      color="#9e75ff"
      stroke="currentColor"
      strokeWidth={1.5}
      height="24"
      width="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
      />
    </svg>
  );
}

export function NoSymbolIcon(props) {
  return (
    <svg
      fill="none"
      color="#9e75ff"
      stroke="currentColor"
      height="24"
      width="24"
      strokeWidth={1.5}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
      />
    </svg>
  );
}

export function DocumentMagnifyingGlassIcon(props) {
  return (
    <svg
      fill="none"
      color="#9e75ff"
      height="24"
      width="24"
      stroke="currentColor"
      strokeWidth={1.5}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
      />
    </svg>
  );
}

import React, { useState } from "react";
import { Form, Dropdown, Button, Modal, Row, Col } from "react-bootstrap";
import { handleBlockedUser, handleDeleteUser } from "../shared/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { rightSelector } from "../redux/rightSlice";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../Components/CustomeLoader";
import { accountSelector } from "../redux/accountSlice";

const SubAdminScreen = ({
  AccountStatusChange,
  subAdminData,
  isPartner,
  dataAfterDelete,
}) => {
  const { rightAccess } = useSelector(rightSelector);
  const [isDelete, setisDelete] = useState(false);
  const navigate = useNavigate();
  const { account } = useSelector(accountSelector);
  const [loader, setloader] = useState(false);

  const BlockeAndUnblock = (userId, activation) => {
    setloader(true)
    handleBlockedUser(userId, activation)
      .then((res) => {
        setloader(false)
        if (!activation) {
          toast.success("Blocked Successfully");
          AccountStatusChange(userId, "Deactive");
        } else {
          toast.success("Unblocked Successfully");
          AccountStatusChange(userId, "Active");
        }
      })
      .catch((error) => { setloader(false) });
  };
  const deleteUser = (id) => {
    setloader(true)
    handleDeleteUser(id)
      .then((res) => {
        setloader(false)
        dataAfterDelete(id);
        toast.info("Deleted Successfully ");
        setisDelete(false);
      })
      .catch((err) => { setloader(false) });
  };
  const handleClose = () => setisDelete(false);

  return (
    <>
      <CustomLoader isLoading={loader} />
      <Modal centered show={isDelete} onHide={handleClose}>
        <Modal.Header>
          <Button closeButton className="close-btn" onClick={handleClose}><i className="fa fa-close"></i></Button>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <h3 style={{ display: "flex", justifyContent: "center" }}>
                Are you sure you want to delete the {isPartner ? 'partner account' : ' user'}?
              </h3>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Row>
            <Col md={6}>
              <Button
                onClick={() => deleteUser(subAdminData.id)}
                style={{ minWidth: "195px" }}
                className="reg-btn w-100 big"
              >
                Yes
              </Button>
            </Col>
            <Col md={6}>
              <Button
                onClick={handleClose}
                style={{ minWidth: "195px" }}
                className="reg-btn w-100 trans big"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <tr key={subAdminData.id}>
        <td>
          {isPartner && subAdminData.firstName}
          {!isPartner && subAdminData.firstName + " " + subAdminData.lastName}
        </td>
        <td>{subAdminData.email}</td>
        <td>{subAdminData.password}</td>
        <td>{subAdminData.roleName}</td>
        <td>


          <ul className="icon-ul">
            {
              // account.accountType==='SuperAdmin'&&
            <>
             {subAdminData.accountStatus === "Deactive" ? (
              <>
                <li title="Unblock" onClick={() => BlockeAndUnblock(subAdminData.id, true)}>

                  <i className="fa fa-unlock" aria-hidden="true"></i>
                </li>
              </>
            ) : (
              <li title="Block" onClick={() => BlockeAndUnblock(subAdminData.id, false)}>

                <i className="fa fa-ban" aria-hidden="true"></i>
              </li>
            )}
            </>
            }

            {!isPartner ? (
              <>
                {rightAccess?.some(
                  (right) =>
                    right.screenName.toLowerCase() ===
                    "subadmin management"
                    && right.isUpdate
                ) && (
                    <li title="Update" onClick={() => navigate(`/UpdateSubadmin/${subAdminData.id}`)}>

                      <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </li>
                  )}
              </>
            )
              : (
                <>
                  {rightAccess?.some(
                    (right) =>

                      right.screenName.toLowerCase() === "partner management"
                      && right.isUpdate
                  ) && (
                      <li title="Update" onClick={() => navigate(`/UpdatePartner/${subAdminData.id}`)}>

                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </li>
                    )}
                </>

              )
            }
            {rightAccess?.some(
              (right) =>
                (right.screenName.toLowerCase() === "subadmin management" ||
                  right.screenName.toLowerCase() === "partner management") &&
                right.isDelete
            ) && (
                <li title="Delete" onClick={() => setisDelete(true)} >
                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                </li>
              )}
            <li title="View" onClick={() => navigate(`/viewSubadmin/${subAdminData.id}`)}>
              <i className="fa fa-eye" aria-hidden="true"></i>
            </li>




          </ul>
          {/* <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              ...
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href={`/viewSubadmin/${subAdminData.id}`}>
                View
              </Dropdown.Item>

              {!isPartner && (
                <>
                  {rightAccess?.some(
                    (right) =>
                      right.screenName.toLowerCase() ===
                      "subadmin management" && right.isUpdate
                  ) && (
                      <Dropdown.Item href={`UpdateSubadmin/${subAdminData.id}`}>
                        Update
                      </Dropdown.Item>
                    )}
                </>
              )}
              {rightAccess?.some(
                (right) =>
                  (right.screenName.toLowerCase() === "subadmin management" ||
                    right.screenName.toLowerCase() === "partner management") &&
                  right.isDelete
              ) && (
                  <Dropdown.Item onClick={() => setisDelete(true)} href="#">
                    Delete
                  </Dropdown.Item>
                )}

              {subAdminData.accountStatus === "Deactive" ? (
                <>
                  <Dropdown.Item
                    onClick={() => BlockeAndUnblock(subAdminData.id, true)}
                    href="#"
                  >
                    Unblock
                  </Dropdown.Item>
                </>
              ) : (
                <Dropdown.Item
                  onClick={() => BlockeAndUnblock(subAdminData.id, false)}
                  href="#"
                >
                  Block
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown> */}
        </td>
      </tr>
    </>
  );
};

export default SubAdminScreen;

import React from 'react';

const Starssvg = ({ fill, height = '400', width = '400', starColor }) => {
  return (
    <div style={{ marginLeft: '5%', marginTop: '40px' }}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 960 1008"
        height={height}
        width={width}
      >
        <defs>
          <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
            <feOffset dx="0" dy="3" />
            <feGaussianBlur result="blur" stdDeviation="3" />
            <feFlood floodColor="#000" floodOpacity=".49" />
            <feComposite in2="blur" operator="in" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <polygon
          className="cls-1"
          points="480 8.01 612.13 94.34 769.77 102.16 825.92 249.67 948.86 348.66 907.59 501 948.86 653.34 825.92 752.33 769.77 899.84 612.13 907.66 480 993.99 347.87 907.66 190.23 899.84 134.08 752.33 11.14 653.34 52.41 501 11.14 348.66 134.08 249.67 190.23 102.16 347.87 94.34 480 8.01"
          style={{
            fill:  starColor,
            filter: 'url(#drop-shadow-1)',
            stroke: '#fff',
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
      </svg>
    </div>
  );
};

export default Starssvg;

import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Button, Modal, Tab, Table, Tabs } from "react-bootstrap";
import CustomLoader from "../Components/CustomeLoader";
import { Pagination } from "react-pagination-bar";
import enUS from "date-fns/locale/en-US";
import { handleGetDiscounts } from "../shared/api";
import { DateRange } from "react-date-range"; // Import DateRange
import "react-date-range/dist/styles.css"; // Include styles for the date range picker
import "react-date-range/dist/theme/default.css";
import { toast } from "react-toastify";
import PaginationComponent from "./PaginationComponent";

const DiscountId = () => {
  const [discounts, setDiscounts] = useState([]);
  const [currentPage, setCurrentPage] = useState({
    usageCount: 1,
    employeeUsage: 1,
    employeeTotal: 1,
  });
  const [totalItems, setTotalItems] = useState({
    usageCount: 0,
    employeeUsage: 0,
    employeeTotal: 0,
  });
  const [loader, setloader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("usageCount"); // Track active tab
  const [discountUsageCount, setDiscountUsageCount] = useState([]);
  const [employeeDiscountUsageCount, setEmployeeDiscountUsageCount] = useState(
    []
  );
  const [employeeTotalDiscounts, setEmployeeTotalDiscounts] = useState([]);

  // State variables for date range
  const [dateRange, setDateRange] = useState([
    {
      startDate: null, // Default to today
      endDate: null, // Default to today
      key: "selection",
    },
  ]);

  useEffect(() => {
    getUserList();
  }, [currentPage[activeTab], dateRange, activeTab]);

  const getUserList = () => {
    setloader(true);
    const { startDate, endDate } = dateRange[0]; // Get the selected start and end date
    const activePage = currentPage[activeTab];

    handleGetDiscounts(activePage, startDate, endDate)
      .then((res) => {
        setDiscounts(res?.data);
        setDiscountUsageCount(res?.data.discountUsageCount || []);
        setEmployeeDiscountUsageCount(
          res?.data.employeeDiscountUsageCount || []
        );
        setEmployeeTotalDiscounts(res?.data.employeeTotalDiscounts || []);
        setTotalItems({
          usageCount: res?.data.totalCounts.discountUsageCount || 0,
          employeeUsage: res?.data.totalCounts.employeeDiscountUsageCount || 0,
          employeeTotal: res?.data.totalCounts.employeeTotalDiscounts || 0,
        });
        setloader(false);
      })
      .catch((error) => {
        if (error?.response && error?.response?.status === 404) {
          toast.error("No discounts found for the specified date range.");
        } else {
          toast.error("Error fetching discounts. Please try again.");
        }
        setloader(false);
      });
  };

  /**
   * Closes the modal and clears the date range by setting both start and end dates to null.
   */
  const handleCloseModal = () => {
    setShowModal(false);
    // Reset dates to today's date when modal is closed
    setDateRange([
      {
        startDate: null, // Set to null or remove the key altogether
        endDate: null, // S
        key: "selection",
      },
    ]);
  };
  const handleFilter = () => {
    setShowModal(false);
    getUserList();
  };
  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">
                  Coupon used by Employees
                </Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrumb */}
            </div>

            <div className="full-div">
              <div className="flex-div-sm">
                <h1>
                  Coupons Used By Employees <span className="view-title"></span>
                </h1>

                {/* </div> */}
                <ul className="btn-lister">
                  <li>
                    <div className="search-pnl">
                      {/* <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Searchbar setsearchedString={setsearchedString} handleSearch={handleSearch} searchedString={searchedString} />
                      </Form> */}
                    </div>
                  </li>

                  <Button
                    className="reg-btn"
                    onClick={() => setShowModal(true)}
                  >
                    <i className="fa fa-plus"></i>Select Date Range
                  </Button>
                </ul>
              </div>
            </div>

            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    className="mb-3 custom-tabs justify-content-center"
                  >
                    <Tab eventKey="usageCount" title="Discount Usage Count">
                      <Table>
                        <thead>
                          <tr>
                            <th>Discount ID</th>
                            <th>Total Usage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {discountUsageCount.length > 0 ? (
                            discountUsageCount.map((item, index) => (
                              <tr key={index}>
                                <td>{item.discountid}</td>
                                <td>{item.total_usage}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <div className="full-div text-center pagination-container">
                        <PaginationComponent
                          totalItems={discountUsageCount}
                          itemsPerPage={10}
                          currentPage={currentPage.usageCount}
                          onPageChange={(pageNumber) => {
                            setCurrentPage((prev) => ({
                              ...prev,
                              usageCount: pageNumber,
                            }));
                          }}
                          customClassNames={{
                            rpbItemClassName: "custom-item",
                            rpbItemClassNameActive: "custom-item--active",
                            rpbGoItemClassName: "custom-go-item",
                            rpbItemClassNameDisable: "custom-item--disable",
                            rpbProgressClassName: "custom-progress-bar",
                            rpbRootClassName: "custom-root",
                          }}
                        />
                      </div>
                    </Tab>

                    <Tab
                      eventKey="employeeUsage"
                      title="Employee Discount Usage"
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th>Company ID</th>
                            <th>Location ID</th>
                            <th>Employee ID</th>
                            <th>Employee Name</th>
                            <th>Discount ID</th>
                            <th>Discount Usage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {employeeDiscountUsageCount.length > 0 ? (
                            employeeDiscountUsageCount.map((item, index) => (
                              <tr key={index}>
                                <td>{item.companyid}</td>
                                <td>{item.locationid}</td>
                                <td>{item.employee_id}</td>
                                <td>{item.employee_name}</td>
                                <td>{item.discountid}</td>
                                <td>{item.discount_usage}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6">No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <div className="full-div text-center pagination-container">
                        <PaginationComponent
                          totalItems={employeeDiscountUsageCount}
                          itemsPerPage={10}
                          currentPage={currentPage.employeeUsage}
                          onPageChange={(pageNumber) => {
                            setCurrentPage((prev) => ({
                              ...prev,
                              employeeUsage: pageNumber,
                            }));
                          }}
                          customClassNames={{
                            rpbItemClassName: "custom-item",
                            rpbItemClassNameActive: "custom-item--active",
                            rpbGoItemClassName: "custom-go-item",
                            rpbItemClassNameDisable: "custom-item--disable",
                            rpbProgressClassName: "custom-progress-bar",
                            rpbRootClassName: "custom-root",
                          }}
                        />
                      </div>
                    </Tab>

                    <Tab
                      eventKey="employeeTotal"
                      title="Employee Total Discounts"
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th>Company ID</th>
                            <th>Location ID</th>
                            <th>Employee ID</th>
                            <th>Employee Name</th>
                            <th>Total Discounts</th>
                          </tr>
                        </thead>
                        <tbody>
                          {employeeTotalDiscounts.length > 0 ? (
                            employeeTotalDiscounts.map((item, index) => (
                              <tr key={index}>
                                <td>{item.companyid}</td>
                                <td>{item.locationid}</td>
                                <td>{item.employee_id}</td>
                                <td>{item.employee_name}</td>
                                <td>{item.total_discounts}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5">No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <div className="full-div text-center pagination-container">
                        <PaginationComponent
                          totalItems={employeeTotalDiscounts}
                          itemsPerPage={10}
                          currentPage={currentPage.employeeTotal}
                          onPageChange={(pageNumber) => {
                            setCurrentPage((prev) => ({
                              ...prev,
                              employeeTotal: pageNumber,
                            }));
                          }}
                          customClassNames={{
                            rpbItemClassName: "custom-item",
                            rpbItemClassNameActive: "custom-item--active",
                            rpbGoItemClassName: "custom-go-item",
                            rpbItemClassNameDisable: "custom-item--disable",
                            rpbProgressClassName: "custom-progress-bar",
                            rpbRootClassName: "custom-root",
                          }}
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            <Button
              closeButton
              className="close-btn"
              onClick={handleCloseModal}
            >
              <i className="fa fa-close"></i>
            </Button>

            <Modal.Title>Select Date Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="date-picker-container">
              <DateRange
                onChange={(item) => setDateRange([item.selection])}
                ranges={dateRange}
                locale={enUS} // Add this line to set the locale
                editableDateInputs
                months={1}
                direction="horizontal"
                moveRangeOnFirstSelection={false}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFilter}>
              Filter
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </>
  );
};

export default DiscountId;

import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Table, Button } from "react-bootstrap";
import axios from "axios";
import CustomLoader from "../Components/CustomeLoader";
import { API_URL } from "../constant";
// import EditIcon from "../SVG/EditIcon";
import { toast } from "react-toastify";
import EditIcon from "../assets/images/edit.png";
const StoreLinks = () => {
  const [locations, setLocations] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [editingId, setEditingId] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const itemsPerPage = 10;

  const inputRef = useRef(null); 

  // Fetch all locations
  const GetAllLocations = (page) => {
    setLoader(true);
    const token = localStorage.getItem("Token");

    axios
      .get(`${API_URL}api/Nft/GetAllStores`, {
        params: { CurrentPage: page },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response, "response");
        if (
          response.data &&
          response.data.data &&
          response.data.data.response
        ) {
          const { response: locationData, totalCount } = response.data.data;

          // Add `tempUrl` for inline editing
          const updatedLocations = locationData.map((location) => ({
            ...location,
            tempUrl: location.url,
          }));
          setLocations(updatedLocations);
          setTotalPages(Math.ceil(totalCount / itemsPerPage));
        } else {
          setLocations([]);
          setTotalPages(1);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.error("Error fetching locations:", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    GetAllLocations(currentPage);
  }, [currentPage]);

  // Handle Input Change
  const handleInputChange = (id, value) => {
    setLocations((prevLocations) =>
      prevLocations.map((location) =>
        location.id === id ? { ...location, tempUrl: value } : location
      )
    );
  };

  useEffect(() => {
    const locationIds = locations.map((location) => location.locationId);
    console.log(locationIds, "locationIds");
  }, [locations]);

  // Handle Save
  const handleSaveUrl = (locationId) => {
    const Token = localStorage.getItem("Token");

    const updatedLocation = locations.find(
      (location) => String(location.locationId) === String(locationId)
    );
    if (!updatedLocation) {
      console.error("Location not found for locationId:", locationId);
      return;
    }
    const { tempUrl } = updatedLocation;
    const apiUrl = `${API_URL}api/Nft/UpdateStoreURL?locationId=${locationId}&url=${encodeURIComponent(
      tempUrl
    )}`;

    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setLocations((prevLocations) =>
            prevLocations.map((location) =>
              String(location.locationId) === String(locationId)
                ? { ...location, url: tempUrl }
                : location
            )
          );
          // Show success toast
          toast.success("URL updated successfully!", {
            autoClose: 2000,
          });
          setEditingId(null);
        } else {
          toast.error("Failed to update URL. Please try again.");
        }
      })
      .catch((err) => {
        toast.error("Error updating URL.");
      });
  };
  const handleEditClick = () => {
    setIsEditable(true); // Make the input editable
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input field after making it editable
    }
  };

  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              <Breadcrumb>
                <Breadcrumb.Item>Admin Management</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Store Links</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="table-container">
              <div className="table-container-inner">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Location Name</th>
                      <th>Company</th>
                      <th>Province</th>
                      <th>URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loader && locations.length > 0
                      ? locations.map((location) => (
                          <tr key={location.id}>
                            <td>{location.locationName}</td>
                            <td>{location.company}</td>
                            <td>{location.province}</td>
                            <td>
                              {location.id === editingId ? (
                                <div className="url-cont">
                                  <a
                                    href={location.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {location.url || "No URL"}
                                  </a>
                                </div>
                              ) : (
                                <div>
                                  <input
                                    type="text"
                                    className="Input-URl"
                                    placeholder="Enter URL here"
                                    value={location.tempUrl || ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        location.id,
                                        e.target.value
                                      )
                                    }
                                    disabled={!isEditable}
                                  />
                                  <img
                                    src={EditIcon}
                                    alt="EditIcon"
                                    className="Editicon"
                                    onClick={handleEditClick}
                                  />

                                  {/* Show Send button only when there is a change in tempUrl */}
                                  {location.tempUrl &&
                                    location.tempUrl !== location.url && (
                                      <Button
                                        type="submit"
                                        className="reg-btn w-1"
                                        style={{ marginLeft: "10px" }}
                                        onClick={() =>
                                          handleSaveUrl(location.locationId)
                                        }
                                      >
                                        Send
                                      </Button>
                                    )}
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                      : !loader && (
                          <tr>
                            <td colSpan="4" className="text-center">
                              No Record Found
                            </td>
                          </tr>
                        )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default StoreLinks;

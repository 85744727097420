import React from "react";
import { Link } from "react-router-dom";
import { Form, Breadcrumb, Table, Dropdown, Button } from "react-bootstrap";
import { Pagination } from "react-pagination-bar";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { handleGetUserList } from "../shared/api";
import SubAdminScreen from "../Components/SubAdminScreen";
import { useState } from "react";
import { useEffect } from "react";
import CustomLoader from "../Components/CustomeLoader";
import { useSelector } from "react-redux";
import { rightSelector } from "../redux/rightSlice";
import { Searchbar } from "../Components/Searchbar";
import OGBXLoader from "../../src/assets/images/Loader.gif"
import PaginationComponent from "./PaginationComponent";

function PartnerManagement() {
  const { rightAccess } = useSelector(rightSelector);
  const [subAdmin, setsubAdmin] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loader, setloader] = useState(true);
  const [searchedString, setsearchedString] = useState('');
  useEffect(() => {


    getUserList()

  }, [currentPage]);

  const getUserList = () => {
    setloader(true);
    handleGetUserList(currentPage, true, searchedString)
      .then((res) => {
        setsubAdmin(res.data.data);
        setTotalItems(res.data.totalItems);
        setloader(false);
      })
      .catch((err) => { setloader(false) });

  }

  useEffect(() => {
    if (searchedString.length == 0) {
      getUserList()
    }
  }, [searchedString]);
  const handleSearch = (e) => {

    setloader(true);

    handleGetUserList(1, true, searchedString)
      .then((res) => {
        setloader(false);
        setsubAdmin(res.data.data);
        setTotalItems(res.data.totalItems);
      })
      .catch((err) => { setloader(false); });
  };



  const dataAfterDelete = (id) => {
    setsubAdmin((prev) => [...subAdmin?.filter((x) => x.id !== id)]);
    setTotalItems(totalItems - 1)
  };

  const AccountStatusChange = (id, status) => {
    const updatedUsers = subAdmin.map((user) => {
      if (user.id == id) {
        return { ...user, accountStatus: status }; // Use the spread operator to create a new object with the updated age
      }
      return user; // Return the original object if the id doesn't match
    });

    setsubAdmin(updatedUsers);
  };
  return (
    <>
      {/* <CustomLoader isLoading={loader} /> */}

      <Sidebar />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">PartnerManagement</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            <div className="full-div">
              <div className="flex-div-sm">
                {/* <div className="full-div"> */}
                {/* <h3
                  style={{
                    color: "#AD79F7",
                    fontSize: "16px",
                    fontWeight: "700",
                    paddingLeft: "15px",
                  }}
                >
                  {totalItems} Users
                </h3> */}
                <h1 >Partner Management  <span
                  style={{
                    color: "#AD79F7",
                    fontSize: "16px",
                    fontWeight: "700",
                    marginLeft: "5px",
                  }}
                >
                  {totalItems} Users
                </span></h1>
                {/* </div> */}
                <ul className="btn-lister">
                  <li>
                    <div className="search-pnl">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Searchbar setsearchedString={setsearchedString} handleSearch={handleSearch} searchedString={searchedString} />
                      </Form>
                    </div>
                  </li>

                  {rightAccess?.some(
                    (right) =>
                      right.screenName.toLowerCase() === "partner management" &&
                      right.isAdd
                  ) && (
                      <li>
                        <Link to="/AddNewPartner" className="reg-btn">
                          <i className="fa fa-plus"></i> Add New Partner
                        </Link>
                      </li>
                    )}
                </ul>
              </div>
            </div>
            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Password</th>
                        <th>Permission</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {!loader ? (subAdmin.length > 0 ? (
                      <tbody>
                        <>
                          {subAdmin?.map((subAdminData) => (
                            <SubAdminScreen
                              subAdminData={subAdminData}
                              isPartner={true}
                              AccountStatusChange={AccountStatusChange}
                              dataAfterDelete={dataAfterDelete}
                            />
                          ))}
                        </>
                      </tbody>
                    ) : (
                      <>
                        <tbody
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 auto",
                          }}
                        >
                          No Record Found
                        </tbody>
                      </>
                    )) : <CustomLoader isLoading={loader} />}
                  </Table>
                </div>
              </div>
              {/* <div className="full-div">
                <h3
                  style={{
                    color: "#AD79F7",
                    fontSize: "16px",
                    fontWeight: "700",
                    paddingLeft: "15px",
                  }}
                >
                  {totalItems} Users
                </h3>
              </div> */}
              <div
                className="full-div text-center pagination-container"
              >
                <PaginationComponent
                  totalItems={totalItems}
                  itemsPerPage={10}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                  customClassNames={{
                    rpbItemClassName: "custom-item",
                    rpbItemClassNameActive: "custom-item--active",
                    rpbGoItemClassName: "custom-go-item",
                    rpbItemClassNameDisable: "custom-item--disable",
                    rpbProgressClassName: "custom-progress-bar",
                    rpbRootClassName: "custom-root",
                  }}
                />
                {/* <Link to="/" className='reg-btn big'>View More</Link> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default PartnerManagement;

import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import {
  placeholderurldesktop,
  placeholderurllandscape,
  placeholderurlmobile,
  placeholderurlMobileNFT,
  placeholderurlpotrait,
  previewSizes,
  REMOVE_BG,
  TabKeys,
} from "../constant";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { Button, Tab, Tabs } from "react-bootstrap";
import Previewmodal from "./Previewmodal";
import ModelMobileNFT from "./ModelMobileNFT";
import LandscrapeModel from "./LandscrapeModel";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../redux/tabSlice";
import CustomLoader from "../Components/CustomeLoader";

const LivePreview = ({
  background,
  title,
  titleColor,
  titleFontSize,
  priceFontSize,
  footer,
  generatedQR,
  textEffect,
  showTcColor,
  FooterColor,
  activePicker,
  showFooterInput,
  showTC,
  nameMapColor,
  titleMapColor,
  footerFontSizes,
  TcFontSizes,
  priceMapColor,
  nameFontSize,
  productDetails,
  titleFontFamily,
  customPrice,
  nameColor,
  priceColor,
}) => {

  const dispatch = useDispatch();
  const [processedImage, setProcessedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorShown, setErrorShown] = useState(false);
  const imgRefs = useRef([]);

  useEffect(() => {
    const removeBackground = async () => {
      setLoading(true);
      try {
        const imageUrl =
          productDetails[0]?.imageurl instanceof Blob
            ? URL.createObjectURL(productDetails[0]?.imageurl)
            : productDetails[0]?.imageurl;

        const response = await axios.get(REMOVE_BG, {
          params: { url: imageUrl },
          responseType: "blob",
        });
        const url = URL.createObjectURL(response.data);
        setProcessedImage(url);
      } catch (error) {
        if (!errorShown) {
          toast.error("Image Not Found");
          setErrorShown(true);
        }
      } finally {
        setLoading(false);
      }
    };

    if (productDetails && productDetails[0]?.imageurl) {
      removeBackground();
    }
  }, [productDetails]);

  const downloadAllBannersAsPng = async () => {
    setDownloadLoading(true); // Start loading
    const imgElements = imgRefs.current;

    for (let i = 0; i < imgElements.length; i++) {
      const img = imgElements[i];
      if (!img) continue;

      const downloadSizes = {
        Greenline_Mobile: { width: "1800px", height: "810px" },
        Greenline_Desktop: { width: "2680px", height: "360px" },
        Menu_Board_TV_Landscape: { width: "1920px", height: "1080px" },
        PORTRAIT_SIZE: { width: "1080px", height: "1920px" },
        Mobile_NFT: { width: "400px", height: "400px" },
      };

      const bannerType = img.getAttribute("data-banner-type");
      const resolution = downloadSizes[bannerType];

      // Check if the resolution is defined
      if (!resolution) {
        console.warn(`No resolution found for banner type: ${bannerType}`);
        continue; // Skip this image if resolution is not defined
      }

      const originalStyle = {
        width: img.style.width,
        height: img.style.height,
      };

      img.style.width = resolution.width;
      img.style.height = resolution.height;

      const canvas = await html2canvas(img, {
        useCORS: true,
        allowTaint: true,
        scale: 1,
        backgroundColor: "#ffffff",
      });

      // Reset original styles
      img.style.width = originalStyle.width;
      img.style.height = originalStyle.height;

      // Convert canvas to blob and save it
      canvas.toBlob((blob) => {
        if (blob) {
          saveAs(blob, `Downloaded-banner-${bannerType}-${i + 1}.png`); // Include banner type in filename
        }
      }, "image/png");
    }

    setDownloadLoading(false); // End loading
  };

  const downloadAll = async () => {
    try {
      setDownloadLoading(true); // Start loading state

      const bannerTypes = [
        "GreenLine Mobile",
        "Greenline Desktop",
        "Menu Board TV",
        "Potrait Size",
        "NFT Size",
      ];

      for (const bannerType of bannerTypes) {
        setActiveTabLive(bannerType); // Set the active tab
        await captureAndDownloadBanner(bannerType); // Wait for the banner to be captured and downloaded
      }

      setDownloadLoading(false); // Stop loading state after all downloads
    } catch (error) {
      toast.dismiss();
      toast.error("Error downloading banners", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setDownloadLoading(false);
    }
  };

  const downloadSizes = {
    "GreenLine Mobile": { width: "1800px", height: "810px" },
    "Menu Board TV": { width: "1920px", height: "1080px" },
    "Greenline Desktop": { width: "2680px", height: "360px" },
    "Potrait Size": { width: "1080px", height: "1920px" },
    "NFT Size": { width: "400px", height: "400px" },
  };

  // Helper function to capture and download the banner
  const captureAndDownloadBanner = async (bannerType) => {
    // Map each banner type to its respective image reference
    const bannerElement =
      bannerType === "GreenLine Mobile"
        ? imgRefs.current[0]
        : bannerType === "Greenline Desktop"
        ? imgRefs.current[1]
        : bannerType === "Menu Board TV"
        ? imgRefs.current[2]
        : bannerType === "Potrait Size"
        ? imgRefs.current[3]
        : bannerType === "NFT Size"
        ? imgRefs.current[4]:
          null;

    if (bannerElement) {
      const resolution = downloadSizes[bannerType];

      // Store the original dimensions
      const originalStyle = {
        width: bannerElement.style.width,
        height: bannerElement.style.height,
      };

      // Set the new resolution for capturing
      bannerElement.style.width = resolution.width;
      bannerElement.style.height = resolution.height;

      const canvas = await html2canvas(bannerElement, {
        useCORS: true,
        allowTaint: true,
        scale: 1,
        backgroundColor: "#ffffff",
      });

      // Restore the original size after capturing
      bannerElement.style.width = originalStyle.width;
      bannerElement.style.height = originalStyle.height;

      // Convert canvas to PNG and download
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = `${bannerType}.png`;
      link.click(); // Trigger the download
    } else {
      console.error(`Banner element for ${bannerType} not found.`);
    }
  };

  const [currentBannerType, setCurrentBannerType] = useState("");

  const handleBannerClick = (bannerType) => {
    setCurrentBannerType(bannerType);
    setShowModal(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [activeTabLive, setActiveTabLive] = useState("GreenLine Mobile");
  const [showPreviewsLive, setShowPreviewsLive] = useState(true);

  const handleTabSelect = (key) => {
    dispatch(setActiveTab(key));
    setActiveTabLive(key);
    if (key === "home") {
      setShowPreviewsLive(false);
    } else if (
      key === TabKeys.GREENLINE_MOBILE ||
      key === TabKeys.GREENLINE_DESKTOP ||
      key === TabKeys.MENU_BOARD_TV ||
      key === TabKeys.PORTRAIT_SIZE ||
      key === TabKeys.NFT_SIZE
    ) {
      setShowPreviewsLive(true); // Show previews for specific tabs
    } else {
      setShowPreviewsLive(false); // Default case to hide if needed
    }
  };

  return (
    <>
      <div className="scrollable-form-container-live">
        <div>
          <div className="d-flex align-items-center">
            <Button
              className="Downloadallbtn"
              onClick={downloadAll}
              disabled={downloadLoading} // Disable the button while loading
            >
              {downloadLoading ? "Downloading..." : "Download All"}
            </Button>

            {downloadLoading && <CustomLoader isLoading={downloadLoading} />}
          </div>
          <Tabs
            id="controlled-tab-example"
            activeKey={activeTabLive}
            onSelect={(k) => handleTabSelect(k)}
            className="mb-3 custom-tab justify-content"
          >
            <Tab eventKey="GreenLine Mobile" title="GreenLine Mobile">
              {activeTabLive === "GreenLine Mobile" && showPreviewsLive && (
                <div
                  style={{
                    visibility:
                      activeTabLive === "GreenLine Mobile"
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <div
                    className="banner-preview greenline-mobile"
                    style={{
                      position: "relative",
                      ...previewSizes.Greenline_Mobile,
                      backgroundImage: `url(${
                        background
                          ? background instanceof Blob
                            ? URL.createObjectURL(background)
                            : background
                          : placeholderurlmobile
                      })`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    ref={(el) => (imgRefs.current[0] = el)}
                    data-banner-type="Greenline_Mobile"
                    onClick={() => handleBannerClick("Greenline_Mobile")}
                  >
                    <div className="banner-content">
                      {title && (
                        <div
                          className={`live-title-mobile ${textEffect}`} // Use textEffect directly
                          style={{
                            fontSize: titleFontSize,
                            color: titleMapColor || titleColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {title}
                        </div>
                      )}

                      {loading ? (
                        <div className="loading-styling">
                          <ClipLoader
                            color={"white"}
                            loading={loading}
                            alignContent="center"
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        <div className="products-container-mobile">
                          {productDetails?.map((product, index) => {
                            const priceInDollars =
                              (customPrice || product.price) / 100;
                            const formattedPrice = priceInDollars.toFixed(2);

                            return (
                              <div
                                key={index}
                                className="product-details-mobile"
                              >
                                {processedImage && (
                                  <img
                                    src={processedImage}
                                    alt={product.name}
                                    className="product-image"
                                  />
                                )}
                                {product?.name && (
                                  <div
                                    className={`${textEffect}`}
                                    style={{
                                      fontSize: nameFontSize,
                                      color: nameMapColor || nameColor,
                                      fontFamily: titleFontFamily,
                                    }}
                                  >
                                    {product.name}
                                  </div>
                                )}
                                {(customPrice || priceInDollars) && (
                                  <div
                                    className={`${textEffect}`}
                                    style={{
                                      fontSize: priceFontSize,
                                      color: priceMapColor || priceColor,
                                      fontFamily: titleFontFamily,
                                    }}
                                  >
                                    ${customPrice || formattedPrice}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {(footer || showFooterInput) && (
                        <div
                          className="footer-style"
                          style={{
                            fontSize: footerFontSizes,
                            color:FooterColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {footer}
                        </div>
                      )}

                      {showTC && (
                        <div
                          className="T-and-C"
                          style={{
                            fontSize: TcFontSizes,
                            color:showTcColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          T & C
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Tab>

            <Tab eventKey="Greenline Desktop" title="Greenline Desktop">
              {activeTabLive === "Greenline Desktop" && showPreviewsLive && (
                <div
                  style={{
                    visibility:
                      activeTabLive === "Greenline Desktop"
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <div
                    className="banner-preview greenline-desktop"
                    style={{
                      position: "relative",
                      ...previewSizes.Greenline_Desktop,
                      backgroundImage: `url(${
                        background
                          ? background instanceof Blob
                            ? URL.createObjectURL(background)
                            : background
                          : placeholderurldesktop
                      })`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    ref={(el) => (imgRefs.current[1] = el)}
                    data-banner-type="Greenline_Desktop"
                    onClick={() => handleBannerClick("Greenline_Desktop")}
                  >
                    {title && (
                      <div
                        className={`live-title ${textEffect}`}
                        style={{
                          fontSize: titleFontSize,
                          color: titleMapColor || titleColor,
                          fontFamily: titleFontFamily,
                        }}
                      >
                        {title}
                      </div>
                    )}

                    {loading ? (
                      <div className="loading-styling">
                        <ClipLoader
                          color={"white"}
                          loading={loading}
                          alignContent="center"
                          size={50}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    ) : (
                      <div className="live-title">
                        {productDetails?.map((product, index) => {
                          const priceInDollars =
                            (customPrice || product.price) / 100;
                          const formattedPrice = priceInDollars?.toFixed(2);

                          return (
                            <div key={index} className="Desktop-imgcont">
                              {processedImage && (
                                <img
                                  src={processedImage}
                                  alt={product.name}
                                  className="Desktop-transparent-image"
                                />
                              )}
                              {product?.name && (
                                <div
                                  className={` Desktop-nameTag ${textEffect}`}
                                  style={{
                                    fontSize: nameFontSize,
                                    color: nameMapColor || nameColor,
                                    fontFamily: titleFontFamily,
                                  }}
                                >
                                  {product.name}
                                </div>
                              )}

                              {(customPrice || product.price) && (
                                <div
                                  className={`Desktop-priceTag ${textEffect}`}
                                  style={{
                                    fontSize: priceFontSize,
                                    color: priceMapColor || priceColor,
                                    fontFamily: titleFontFamily,
                                  }}
                                >
                                  ${customPrice || formattedPrice}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {(footer || showFooterInput) && (
                      <div
                        className="footer-style"
                        style={{
                          fontSize: footerFontSizes,
                          color:FooterColor,
                          fontFamily: titleFontFamily,
                        }}
                      >
                        {footer}
                      </div>
                    )}
                    {showTC && (
                      <div
                        className="T-and-C"
                        style={{
                          fontSize: TcFontSizes, 
                          color:showTcColor,
                          fontFamily: titleFontFamily,
                        }}
                      >
                        T & C
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Tab>

            <Tab eventKey="Menu Board TV" title="MenuBoard Landscape">
              {activeTabLive === "Menu Board TV" && showPreviewsLive && (
                <div
                  style={{
                    visibility:
                      activeTabLive === "Menu Board TV" ? "visible" : "hidden",
                  }}
                >
                  <div
                    className="banner-preview greenline-mobile"
                    style={{
                      position: "relative",
                      ...previewSizes.Menu_Board_TV_Landscape,
                      backgroundImage: `url(${
                        background
                          ? background instanceof Blob
                            ? URL.createObjectURL(background)
                            : background
                          : placeholderurllandscape
                      })`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    ref={(el) => (imgRefs.current[2] = el)}
                    data-banner-type="Menu_Board_TV_Landscape"
                    onClick={() => handleBannerClick("Menu_Board_TV_Landscape")}
                  >
                    <div
                      className={`live-title-landscape ${textEffect}`}
                      style={{
                        fontSize: titleFontSize,
                        color: titleMapColor || titleColor,
                        fontFamily: titleFontFamily,
                      }}
                    >
                      {title}
                    </div>

                    <div className="banner-content-landscape">
                      {loading ? (
                        <div className="loading-styling">
                          <ClipLoader
                            color={"white"}
                            loading={loading}
                            alignContent="center"
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        <div>
                          {productDetails?.length > 0 ? (
                            productDetails?.map((product, index) => {
                              // Calculate price in dollars and format it
                              const priceInDollars =
                                (customPrice || product.price) / 100;
                              const formattedPrice = priceInDollars?.toFixed(2);

                              return (
                                <div
                                  key={index}
                                  className="Landscape-productContainer"
                                >
                                  <div className="Landscape-imgPriceContainer">
                                    {processedImage && (
                                      <img
                                        src={processedImage}
                                        alt={product.name}
                                        className="Landscape-transparent-image"
                                      />
                                    )}

                                    {(customPrice || product.price) && (
                                      <div
                                        className={`Landscape-priceTag ${textEffect}`}
                                        style={{
                                          fontSize: priceFontSize,
                                          color: priceMapColor || priceColor,
                                          fontFamily: titleFontFamily,
                                        }}
                                      >
                                        ${customPrice || formattedPrice}
                                      </div>
                                    )}
                                  </div>

                                  {product?.name && (
                                    <div
                                      className={`Landscape-nameTag ${textEffect}`}
                                      style={{
                                        fontSize: nameFontSize,
                                        color: nameMapColor || nameColor,
                                        fontFamily: titleFontFamily,
                                      }}
                                    >
                                      {product.name}
                                    </div>
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <div></div> // Optional: Inform the user if no products are selected
                          )}
                        </div>
                      )}

                      {(footer || showFooterInput) && (
                        <div
                          className="footer-style-landscape-live"
                          style={{
                            fontSize: footerFontSizes,
                            color:FooterColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {footer}
                        </div>
                      )}

                      {showTC && (
                        <div
                          className="T-and-C-landscape-live"
                          style={{
                            fontSize: TcFontSizes, 
                            color:showTcColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          T & C
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Tab>

            <Tab eventKey="Potrait Size" title="Potrait Size">
              {activeTabLive === "Potrait Size" && showPreviewsLive && (
                <div
                  style={{
                    visibility:
                      activeTabLive === "Potrait Size" ? "visible" : "hidden",
                  }}
                >
                  <div
                    className="banner-preview portrait"
                    style={{
                      position: "relative",
                      ...previewSizes.PORTRAIT_SIZE,
                      backgroundImage: `url(${
                        background
                          ? background instanceof Blob
                            ? URL.createObjectURL(background)
                            : background
                          : placeholderurlpotrait
                      })`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    ref={(el) => (imgRefs.current[3] = el)}
                    data-banner-type="PORTRAIT_SIZE"
                    onClick={() => handleBannerClick("PORTRAIT_SIZE")}
                  >
                    <div className="banner-content">
                      {title && (
                        <h1
                          className={`live-title-portrait ${textEffect}`}
                          style={{
                            fontSize: titleFontSize,
                            color: titleMapColor || titleColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {title}
                        </h1>
                      )}

                      {loading ? (
                        <div className="loading-styling">
                          <ClipLoader
                            color={"white"}
                            loading={loading}
                            alignContent="center"
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        <div className="products-container-mobile">
                          {productDetails?.map((product, index) => {
                            // Calculate price in dollars and format it
                            const priceInDollars =
                              (customPrice || product.price) / 100;
                            const formattedPrice = priceInDollars.toFixed(2);

                            return (
                              <div
                                key={index}
                                className="product-details-mobile"
                              >
                                {processedImage && (
                                  <img
                                    src={processedImage}
                                    alt={product.name}
                                    className="Portrait-transparent-image"
                                  />
                                )}
                                {product?.name && (
                                  <div
                                    className={` Portrait-nameTag ${textEffect}`}
                                    style={{
                                      fontSize: nameFontSize,
                                      color: nameMapColor || nameColor,
                                      fontFamily: titleFontFamily,
                                    }}
                                  >
                                    {product.name}
                                  </div>
                                )}
                                {(customPrice || product.price) && (
                                  <div
                                    className={` Portrait-priceTag ${textEffect}`}
                                    style={{
                                      fontSize: priceFontSize,
                                      color: priceMapColor || priceColor,
                                      fontFamily: titleFontFamily,
                                    }}
                                  >
                                    ${customPrice || formattedPrice}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {(footer || showFooterInput) && (
                        <div
                          className="footer-style-Portrait"
                          style={{
                            fontSize: footerFontSizes,
                            color:FooterColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {footer}
                        </div>
                      )}

                      {showTC && (
                        <div
                          className="T-and-C-Portrait"
                          style={{
                            fontSize: TcFontSizes, 
                            color:showTcColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          T & C
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Tab>

            <Tab eventKey="NFT Size" title="NFT Size">
              {activeTabLive === "NFT Size" && showPreviewsLive && (
                <div
                  style={{
                    visibility:
                      activeTabLive === "NFT Size" ? "visible" : "hidden",
                  }}
                >
                  <div
                    className="banner-preview portrait"
                    style={{
                      position: "relative",
                      ...previewSizes.Mobile_NFT,
                      backgroundImage: `url(${
                        background
                          ? background instanceof Blob
                            ? URL.createObjectURL(background)
                            : background
                          : placeholderurlMobileNFT
                      })`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    ref={(el) => (imgRefs.current[4] = el)} // Ensure this is the correct index
                    data-banner-type="Mobile_NFT"
                    onClick={() => handleBannerClick("Mobile_NFT")}
                  >
                    <div className="banner-content">
                      {title && (
                        <div
                          className={`live-title-Mobile-NFT ${textEffect}`}
                          style={{
                            fontSize: titleFontSize,
                            color: titleMapColor || titleColor,
                            fontFamily: titleFontFamily,
                          }}
                        >
                          {title}
                        </div>
                      )}

                      {loading ? (
                        <div className="loading-styling">
                          <ClipLoader
                            color={"white"}
                            loading={loading}
                            alignContent="center"
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : (
                        <div className="products-container-mobile">
                          {productDetails?.map((product, index) => (
                            <div className="product-details-NFT">
                              {processedImage && (
                                <img
                                  src={processedImage}
                                  alt={product.name}
                                  className="NFT-transparent-image"
                                />
                              )}
                              {product.name && (
                                <div
                                  className={` NFT-Name ${textEffect}`}
                                  style={{
                                    fontSize: nameFontSize,
                                    color: nameMapColor || nameColor,
                                    fontFamily: titleFontFamily,
                                  }}
                                >
                                  {product.name}
                                </div>
                              )}
                              {(customPrice || product.price) && (
                                <div
                                  className={` NFT-Price ${textEffect}`}
                                  style={{
                                    fontSize: priceFontSize,
                                    color: priceMapColor || priceColor,
                                    fontFamily: titleFontFamily,
                                  }}
                                >
                                  ${customPrice || product.price / 100}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                      <div>
                        {(footer || showFooterInput) && (
                          <div
                            className="footer-style-NFT"
                            style={{
                              color:FooterColor,
                              fontFamily: titleFontFamily,
                            }}
                          >
                            {footer}
                          </div>
                        )}

                        {showTC && (
                          <div
                            className="T-and-C-NFT"
                            style={{
                              color:showTcColor,
                              fontFamily: titleFontFamily,
                            }}
                          >
                            T & C
                          </div>
                        )}
                        {generatedQR && (
                          <div className="QR-Generator">
                            <QRCode value={generatedQR} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Tab>
          </Tabs>
          {background && (
            <Button
              className="DownloadBannerbtn"
              onClick={downloadAllBannersAsPng}
              disabled={downloadLoading}
            >
              {downloadLoading ? "Downloading..." : "Download Banner"}
            </Button>
          )}
          {downloadLoading && (
            <div className="loader-container">
              <CustomLoader isLoading={downloadLoading} />
            </div>
          )}
        </div>
      </div>
      {currentBannerType === "Mobile_NFT" ? (
        <ModelMobileNFT
          show={showModal}
          handleClose={handleCloseModal}
          background={background}
          title={title}
          activePicker={activePicker}
          generatedQR={generatedQR}
          titleColor={titleColor}
          titleMapColor={titleMapColor}
          titleFontSize={titleFontSize}
          textEffect={textEffect}
          priceFontSize={priceFontSize}
          footer={footer}
          nameMapColor={nameMapColor}
          processedImage={processedImage}
          showFooterInput={showFooterInput}
          showTC={showTC}
          nameFontSize={nameFontSize}
          productDetails={productDetails}
          titleFontFamily={titleFontFamily}
          customPrice={customPrice}
          nameColor={nameColor}
          priceColor={priceColor}
          priceMapColor={priceMapColor}
          bannerType={currentBannerType}
        />
      ) : currentBannerType === "Menu_Board_TV_Landscape" ? (
        <LandscrapeModel
          show={showModal}
          handleClose={handleCloseModal}
          background={background}
          title={title}
          activePicker={activePicker}
          titleColor={titleColor}
          priceMapColor={priceMapColor}
          titleMapColor={titleMapColor}
          titleFontSize={titleFontSize}
          priceFontSize={priceFontSize}
          footer={footer}
          textEffect={textEffect}
          processedImage={processedImage}
          showFooterInput={showFooterInput}
          showTC={showTC}
          nameMapColor={nameMapColor}
          nameFontSize={nameFontSize}
          productDetails={productDetails}
          titleFontFamily={titleFontFamily}
          customPrice={customPrice}
          nameColor={nameColor}
          priceColor={priceColor}
          bannerType={currentBannerType}
        />
      ) : (
        <Previewmodal
          show={showModal}
          handleClose={handleCloseModal}
          background={background}
          title={title}
          titleColor={titleColor}
          titleMapColor={titleMapColor}
          titleFontSize={titleFontSize}
          priceFontSize={priceFontSize}
          footer={footer}
          nameMapColor={nameMapColor}
          activePicker={activePicker}
          priceMapColor={priceMapColor}
          textEffect={textEffect}
          processedImage={processedImage}
          showFooterInput={showFooterInput}
          showTC={showTC}
          nameFontSize={nameFontSize}
          productDetails={productDetails}
          titleFontFamily={titleFontFamily}
          customPrice={customPrice}
          nameColor={nameColor}
          priceColor={priceColor}
          bannerType={currentBannerType}
        />
      )}
    </>
  );
};

export default LivePreview;

// actions.js
export const SET_SELECTED_COLOR = "SET_SELECTED_COLOR";
export const SET_SELECTED_NAME_COLOR = "SET_SELECTED_NAME_COLOR"; // New action for name color

export const setSelectedColor = (color) => ({
  type: SET_SELECTED_COLOR,
  payload: color,
});

export const setSelectedNameColor = (nameColor) => ({
  type: SET_SELECTED_NAME_COLOR,
  payload: nameColor,
});

import React, { useState } from "react";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import { handleDeleteNFT } from "../shared/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { rightSelector } from "../redux/rightSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const CouponScreen = ({ couponData, dataAfterDelete }) => {
  const { rightAccess } = useSelector(rightSelector);
  const navigate = useNavigate()
  const [isDelete, setisDelete] = useState(false);
  const handleClose = () => setisDelete(false);

  const deleteCoupon = (id) => {
    handleDeleteNFT(id)
      .then((res) => {
        res.data.isSuccess && toast.info("Coupon Deleted Successfully");
        dataAfterDelete(id);
        setisDelete(false);
      })
      .catch((error) => {});
  };
  return (
    <>
      <Modal centered show={isDelete} onHide={handleClose}>
        <Modal.Header>
          <Button closeButton className="close-btn" onClick={handleClose}>
            <i className="fa fa-close"></i>
          </Button>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <h3 style={{ display: "flex", justifyContent: "center" }}>
                Are you sure you want to delete this Coupon!
              </h3>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Row>
            <Col md={6}>
              <Button
                onClick={() => deleteCoupon(couponData.id)}
                style={{ minWidth: "195px" }}
                className="reg-btn w-100 big"
              >
                Yes
              </Button>
            </Col>
            <Col md={6}>
              <Button
                onClick={handleClose}
                style={{ minWidth: "195px" }}
                className="reg-btn w-100 trans big"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <tr>
        <td>{couponData.name}</td>
        {/* <td>{couponData.nFtName}</td> */}
        <td>{couponData?.expirationDate?.split("T")[0]}</td>
        <td>
          {couponData?.redemptionDate
            ? moment(couponData?.redemptionDate).format("YYYY-MM-DD HH:mm")
            : "It has not been redeemed yet."}
        </td>

        <td>
          {/* {couponData?.isClaim === true
            ? "Claimed"
            : couponData?.status === "Distributed"
            ? "Distributed"
            : "Pending"}
          {couponData?.isClaim === false
            ? ""
            : !(
                couponData.status === "Distributed" ||
                couponData.status === "Redeemed"
              ) && ` (${couponData.nftCopy})`} */}
            {couponData?.status}{couponData.nftCopy !== 0 && <span> ({couponData.nftCopy})</span>}
 
        </td>
        <td>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              ...
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* {rightAccess?.some(
                (right) =>
                  right.screenName.toLowerCase() === "coupon management" &&
                  right.isDelete
              ) && (
                  <Dropdown.Item
                    onClick={() => setisDelete(true)}
                    href="#"
                  >
                    Delete
                  </Dropdown.Item>
                )}
              {rightAccess?.some(
                (right) =>
                  right.screenName.toLowerCase() === "coupon management" &&
                  right.isUpdate
              ) && (
                  <Dropdown.Item
                    onClick={() => navigate(`/updateCoupon/${couponData.id}`)}
                    href="#"
                  >
                    Update
                  </Dropdown.Item>
                )} */}
              <Dropdown.Item href={`/CollectionDetail/${couponData.id}`}>
                View
              </Dropdown.Item>

              {/* <Dropdown.Item href="#/action-2">Delete Coupon</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
};

export default CouponScreen;

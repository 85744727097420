import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "react-toastify/dist/ReactToastify.css";
import ProductModal from "./ProductModal";
import LivePreview from "./LivePreview";
import {
  ColorPickerModes,
  Default,
  defaultFontSizes,
  DESCRIPTION_CONFIG,
  fontFamilies,
  fontSizes,
  FooterSelectorfontSizes,
  INITIAL_Font_Size_name,
  INITIAL_nameFontSizeMobile,
  INITIAL_priceFontSizeMobile,
  INITIAL_TITLE_COLOR,
  INITIAL_TITLE_COLOR_PDF,
  INITIAL_TITLE_FONT_FAMILY,
  INITIAL_titleFontSizeMobile,
  NamePickerModes,
  sizes,
  Star_Color,
  store_Settings,
  TextEffectTypes,
  Update_Settings,
} from "../constant";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  Tab,
  Tabs,
} from "react-bootstrap";
import PdfScreens from "./PdfScreens";
import PdfModal from "./PdfModal";
import "react-color-palette/css";
import neonImg from "../assets/images/Neon.png";
import noneImg from "../assets/images/None.png";
import echoImg from "../assets/images/Echo.png";
import SpliceImg from "../assets/images/Splice.png";
import OutLineImg from "../assets/images/OutLine.png";
import Shadow from "../assets/images/Shadow.png";
import Lift from "../assets/images/Lift.png";
import Gradient from "../assets/images/gradient.png";
import { activeTabSelector } from "../redux/tabSlice";
import { useSelector } from "react-redux";
import ThreeDot from "../assets/images/threeDots.png";
import axios from "axios";
import ColorMapPicker from "./ColorMapPicker";
import { toast } from "react-toastify";

// Debaunce Function added Calllbacks and delays to the background to avoid flickering
const useDebounce = (callback, delay) => {
  const timerRef = useRef();

  const debouncedCallback = useCallback(
    (...args) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  return debouncedCallback;
};

const BannerForm = ({ onFormSubmit }) => {
  const activeTabLive = useSelector(activeTabSelector);
  const [titleFontSizes, setTitleFontSizes] = useState(
    INITIAL_titleFontSizeMobile
  );
  const [background, setBackground] = useState(null);
  const [pdfbackground, setPdfBackground] = useState(null);
  const [title, setTitle] = useState("");
  const [pdfTitle, setPdfTitle] = useState("");
  const [discription, setDiscription] = useState("");
  const [qrText, setQrText] = useState("");
  const [generatedQR, setGeneratedQR] = useState("");
  const [footer, setFooter] = useState("");
  const [pdfFooter, setPdfFooter] = useState("");
  const [product, setProduct] = useState("");
  const [size, setSize] = useState("");
  const [pdfTitleColor, setPdfTitleColor] = useState(INITIAL_TITLE_COLOR_PDF);
  const [starColor, setStarColor] = useState(Star_Color);
  const [titleColor, setTitleColor] = useState(INITIAL_TITLE_COLOR);
  const [nameColor, setNameColor] = useState(INITIAL_TITLE_COLOR);
  const [pdfNameColor, setPdfNameColor] = useState(INITIAL_TITLE_COLOR);
  const [priceColor, setPriceColor] = useState();
  const [FooterColor, setFooterColor] = useState();
  const [successToastShown, setSuccessToastShown] = useState(false);
  const [errorToastShown, setErrorToastShown] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [fontSettings, setFontSettings] = useState({});
  const [pdfPriceColor, setPdfPriceColor] = useState(INITIAL_TITLE_COLOR);
  const [productDetails, setProductDetails] = useState([]);
  const [pdfProductDetails, setPdfProductDetails] = useState([]);
  const [showFooterInput, setShowFooterInput] = useState(false);
  const [showFooterPdf, setShowFooterPdf] = useState(false);
  const [showTC, setShowTC] = useState(false);
  const [showTcColor, setShowTcColor] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [titleFontSize, setTitleFontSize] = useState("50px");
  const [TcFontSizes, setTcFontSizes] = useState({});
  const [footerFontSizes, setFooterFontSizes] = useState({});
  const [pdftitleFontSize, setPdfTitleFontSize] = useState("");
  const [titleFontFamily, setTitleFontFamily] = useState(
    INITIAL_TITLE_FONT_FAMILY
  );
  const [pdfTitleFontFamily, setPdfTitleFontFamily] = useState(
    INITIAL_TITLE_FONT_FAMILY
  );
  const [nameFontSize, setNameFontSize] = useState(INITIAL_Font_Size_name);
  const [pdfnameFontSize, setPdfNameFontSize] = useState(
    INITIAL_Font_Size_name
  );
  const [priceFontSize, setPriceFontSize] = useState("");
  const [pdfPriceFontSize, setPdfPriceFontSize] = useState("");
  const [price, setPrice] = useState("");
  const [PdfPrice, setPdfPrice] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [customPrice, setCustomPrice] = useState("");
  const [isPdfEditing, setIsPdfEditing] = useState(false);
  const [pdfcustomPrice, setPdfCustomPrice] = useState("");
  const [regularPrice, setRegularPrice] = useState("");
  const [isEditRegular, setIsEditRegular] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [textEffect, setTextEffect] = useState("");
  const [activeTitlePicker, setActiveTitlePicker] = useState(null);
  const [activePriceBgPickerPdf, setActivePriceBgPickerPdf] = useState(null);
  const [activeTitlePickerPdf, setActiveTitlePickerPdf] = useState(null);
  const [activeNamePicker, setActiveNamePicker] = useState(null);
  const [activeNamePickerPdf, setActiveNamePickerPdf] = useState(null);
  const [activePricePicker, setActivePricePicker] = useState(null);
  const [activeFooterPicker, setActiveFooterPicker] = useState(null);
  const [activeTCPicker, setActiveTCPicker] = useState(null);
  const [activeFooterPdfPicker, setActiveFooterPdfPicker] = useState(null);
  const [activePricePickerPdf, setActivePricePickerPdf] = useState(null);

  /**
   * Function to resize an image file and add optional footer text and T&C text.
   * The function reads the image, resizes it to fit within specified dimensions
   * while maintaining the aspect ratio, and optionally adds footer text and
   * T&C text before returning the resized image file.
   *
   * @param {File} file - The image file to be resized.
   * @returns {Promise<File>} - A promise that resolves with the resized image file.
   *
   * The resizing process includes:
   * - Reading the image file using FileReader.
   * - Creating an Image object and setting its source to the file data.
   * - Calculating the target width and height based on the selected size
   *   and aspect ratio.
   * - Drawing the resized image onto a canvas.
   *
   * Optional features:
   * - Adding footer text: The text is wrapped and centered at the bottom of
   *   the image if the `footer` variable is provided.
   * - Adding "T&C" text: If the `showTC` variable is true, the text "T&C" is
   *   added in the bottom-left corner of the image.
   *
   * Finally, the canvas content is converted to a Blob and wrapped in a File object,
   * which is then returned by resolving the promise.
   */
  // Ensure default font size is set properly
  const defaultFontSize = fontSizes[0] || "Select Size";

  useEffect(() => {
    if (titleFontSizes[activeTabLive] === undefined) {
      setTitleFontSizes((prevState) => ({
        ...prevState,
        [activeTabLive]: defaultFontSize, // Fallback to default size
      }));
    }
  }, [activeTabLive, titleFontSizes]);

  // Handle font size change
  const handleFontSizeChange = (e) => {
    const newFontSize = e.target.value;
    setTitleFontSizes((prevState) => ({
      ...prevState,
      [activeTabLive]: newFontSize, // Update font size for the current active tab
    }));
  };

  

  const handleImageResize = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const selectedSize = sizes.find((s) => s.name === size);
          const canvas = document.createElement("canvas");
          const aspectRatio =
            selectedSize.dimensions.width / selectedSize.dimensions.height;
          let targetWidth, targetHeight;

          if (img.width / img.height > aspectRatio) {
            targetHeight = selectedSize.dimensions.height;
            targetWidth = targetHeight * (img.width / img.height);
          } else {
            targetWidth = selectedSize.dimensions.width;
            targetHeight = targetWidth * (img.height / img.width);
          }

          canvas.width = selectedSize.dimensions.width;
          canvas.height = selectedSize.dimensions.height;
          const ctx = canvas.getContext("2d");

          const xOffset = (selectedSize.dimensions.width - targetWidth) / 2;
          const yOffset = (selectedSize.dimensions.height - targetHeight) / 2;

          ctx.drawImage(img, xOffset, yOffset, targetWidth, targetHeight);

          // Function to wrap text
          const wrapText = (ctx, text, x, y, maxWidth, lineHeight) => {
            const words = text.split(" ");
            let line = "";
            for (let n = 0; n < words.length; n++) {
              const testLine = line + words[n] + " ";
              const metrics = ctx.measureText(testLine);
              const testWidth = metrics.width;
              if (testWidth > maxWidth && n > 0) {
                ctx.fillText(line, x, y);
                line = words[n] + " ";
                y += lineHeight;
              } else {
                line = testLine;
              }
            }
            ctx.fillText(line, x, y);
          };

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
            });
            resolve(resizedFile);
          }, file.type);
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title.trim()) {
      toast.error("Title is required.");
      return;
    }

    if (!product.trim()) {
      toast.error("Product name is required.");
      return;
    }

    if (!size) {
      toast.error("Size selection is required.");
      return;
    }

    let resizedImage = null;
    if (pdfbackground) {
      resizedImage = await handleImageResize(pdfbackground);
    }

    const selectedPdfProduct = pdfProductDetails.find(
      (p) => p.name?.toLowerCase() === product.toLowerCase()
    );
    if (!selectedPdfProduct) {
      toast.error("Invalid product selection.");
      return;
    }

    onFormSubmit({
      background: resizedImage,
      title,
      product: selectedPdfProduct,
      size,
      titleColor,
      nameColor,
      priceColor,
      titleFontSize,
      titleFontFamily,
      nameFontSize,
      priceFontSize,
      customPrice: customPrice,
    });
  };

  const randomBorderRadius = Math.floor(Math.random() * 15) + "px";
  /**
   * Function to handle the selection of a product from a list.
   * When a product is selected, this function updates the state with
   * the selected product's name and details, and then closes the modal.
   *
   * @param {Object} selectedProduct - The product object that has been selected.
   *
   * The function performs the following steps:
   * - Updates the `product` state with the name of the selected product.
   * - Updates the `productDetails` state with an array containing the selected product.
   * - Closes the modal by setting the `isModalOpen` state to false.
   */

  const handleSelectProduct = (selectedProduct) => {
    setProduct(selectedProduct.name);
    setProductDetails([selectedProduct]); // Set the selected product details
    setIsModalOpen(false); // Close the modal
  };

  const [pdfIsModalOpen, setPdfIsModalOpen] = useState(false);
  const [pdfProduct, setPdfProduct] = useState("");

  const handlePdfSelectProduct = (selectedPdfProduct) => {
    setPdfProduct(selectedPdfProduct.name);
    setPdfProductDetails([selectedPdfProduct]); // Set the selected product details
    setPdfIsModalOpen(false);
  };

  useEffect(() => {
    if (!isEditing) {
      setCustomPrice(price);
    }
  }, [isEditing, price]);

  const handlePriceChange = (e) => {
    setCustomPrice(e.target.value);
  };
  useEffect(() => {
    if (!isPdfEditing) {
      setPdfCustomPrice(PdfPrice);
    }
  }, [isPdfEditing, PdfPrice]);

  const handlePdfPriceChange = (e) => {
    setPdfCustomPrice(e.target.value);
  };

  const handleBlur = () => {
    if (!isNaN(customPrice)) {
      setPrice(parseFloat(customPrice));
    }
    setIsEditing(false);
  };
  const handlePdfBlur = () => {
    if (!isNaN(pdfcustomPrice)) {
      setPdfPrice(parseFloat(pdfcustomPrice));
    }
    setIsPdfEditing(false);
  };

  const handleGenerateQR = () => {
    setGeneratedQR(qrText);
  };
  const handleRegularPriceChange = (e) => {
    setRegularPrice(e.target.value); // Correctly update the price
  };

  const handleRegularBlur = () => {
    setIsEditRegular(false);
  };

  const [useUploadedBackground, setUseUploadedBackground] = useState(true);

  const handleCheckboxChange = () => {
    setUseUploadedBackground(!useUploadedBackground);
  };
  const [activeTab, setActiveTab] = useState("livePreview");
  const [showPreviews, setShowPreviews] = useState(true);

  const handleTabSelect = (key) => {
    setActiveTab(key); // Update the activeTab state
    if (key === "home") {
      setShowPreviews(false); // Hide previews for 'home' tab
    } else {
      setShowPreviews(true); // Show previews for other tabs
    }
  };
  const [description, setDescription] = useState("");

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
  
    if (inputValue.length <= DESCRIPTION_CONFIG.MAX_LENGTH) {
      setDescription(inputValue);
    }
  
    // Adjust height of the textarea dynamically
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleCheckSoldOut = (e) => {
    setIsChecked(e.target.checked);
  };
  const [inputValue, setInputValue] = useState(""); // Local state for the input
  // that will set Debaunce on title that user add
  const setTitleDebounced = useDebounce((newTitle) => {
    setTitle(newTitle); // Update the main title state after delay
  }, 150);

  const handleTitleChange = (e) => {
    const newValue = e.target.value.toUpperCase(); // Convert to uppercase
    setInputValue(newValue); // Update local input state immediately
    setTitleDebounced(newValue); // Debounce the main state update
  };

  const memoizedBackgroundUrl = useMemo(
    () => (background ? URL.createObjectURL(background) : null),
    [background]
  );

  const memoizedProductDetails = useMemo(
    () => productDetails,
    [productDetails]
  );
  const memoizedTitle = useMemo(() => title, [title]);

  const [nameFontSizes, setNameFontSizes] = useState(
    INITIAL_nameFontSizeMobile
  );
  // useEffect to reset product name font size for new active tab
  useEffect(() => {
    if (nameFontSizes[activeTabLive] === undefined) {
      setNameFontSizes((prevState) => ({
        ...prevState,
        [activeTabLive]: fontSizes[0], // Reset to first size for the new active tab
      }));
    }
  }, [activeTabLive, nameFontSizes, fontSizes]);

  const handleNameFontSizeChange = (e) => {
    const newFontSize = e.target.value;
    setNameFontSizes((prevState) => ({
      ...prevState,
      [activeTabLive]: newFontSize, // Update font size for the current active tab
    }));
  };
  const [priceFontSizes, setPriceFontSizes] = useState(
    INITIAL_priceFontSizeMobile
  );

  // useEffect to reset price font size for new active tab

  useEffect(() => {
    if (priceFontSizes[activeTabLive] === undefined) {
      setPriceFontSizes((prevState) => ({
        ...prevState,
        [activeTabLive]: fontSizes[0], // Reset to first size for the new active tab
      }));
    }
  }, [activeTabLive, priceFontSizes, fontSizes]);

  // Handle font size change for price
  const handlePriceFontSizeChange = (e) => {
    const newFontSize = e.target.value;
    setPriceFontSizes((prevState) => ({
      ...prevState,
      [activeTabLive]: newFontSize, // Update font size for the current active tab
    }));
  };

  const [isOpen, setIsOpen] = useState(false);
  const textEffects = [
    { value: "", label: "None", imgSrc: noneImg },
    { value: "neon", label: "Neon", imgSrc: neonImg },
    { value: "echo", label: "Echo", imgSrc: echoImg },
    { value: "splice", label: "Splice", imgSrc: SpliceImg },
    { value: "outline", label: "Outline", imgSrc: OutLineImg },
    { value: "shadow", label: "Shadow", imgSrc: Shadow },
    { value: "lift", label: "Lift", imgSrc: Lift },
    { value: "gradient", label: "Gradient", imgSrc: Gradient },
  ];

  // Updates the textEffect state with the selected effect's value and closes
  const handleSelectEffect = (effect) => {
    setTextEffect(effect.value);
    setIsOpen(false); // Close dropdown after selection
  };

  // Functions to handle color changes based on active picker
  const handleTitleColorChange = (color) => {
    setTitleColor(color);
  };
  const handleNameColorChange = (color) => {
    setNameColor(color);
  };
  const handleFooterColorChange = (color) => {
    setFooterColor(color);
  };
  const handleTcColorChange = (color) => {
    setShowTcColor(color);
  };
  const handleFooterPdfColorChange = (color) => {
    setShowFooterPdf(color);
  };

  const handlePriceColorChange = (color) => {
    setPriceColor(color);
  };

  const handleTitleColorChangePdf = (color) => {
    setPdfTitleColor(color);
  };
  const handleNameColorChangePdf = (color) => {
    setPdfNameColor(color);
  };

  const handlePriceColorChangePdf = (color) => {
    setPdfPriceColor(color);
  };

  const handleStarColorChangePdf = (color) => {
    setStarColor(color);
  };

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    const checkSettingsAvailability = async () => {
      const apiUrl = `${Default}/${activeTabLive.replace(" ", "%20")}`;

      try {
        const response = await axios.get(apiUrl);
        const settings = response.data;

        // Update isDataAvailable based on settings availability
        setIsDataAvailable(Boolean(settings && settings.tab === activeTabLive));
      } catch (error) {
        setIsDataAvailable(false);
      }
    };

    if (isOpened) {
      checkSettingsAvailability();
    }
  }, [isOpened, activeTabLive]);

  const handleApplyDefaultSettings = () => {
    const apiUrl = `${Default}/${activeTabLive.replace(" ", "%20")}`;

    axios
      .get(apiUrl)
      .then((response) => {
        const settings = response.data;
        // Check if the settings data is available
        setIsDataAvailable(Boolean(settings));

        if (settings.tab === activeTabLive) {
          const {
            _id,
            titleFontSize,
            productionFontSize,
            productFontSize,
            fontFamily,
            termsConditionsFontSize,
            footerFontSize,
            titleColor,
            priceColor,
            nameColor,
          } = settings;

          // Update title font size
          setTitleFontSizes((prev) => ({
            ...prev,
            [activeTabLive]: titleFontSize || defaultFontSize,
          }));

          // Update name font sizes
          setNameFontSizes((prev) => ({
            ...prev,
            [activeTabLive]: productionFontSize || fontSizes[0],
          }));

          // Update price font sizes
          setPriceFontSizes((prev) => ({
            ...prev,
            [activeTabLive]: productFontSize || fontSizes[0],
          }));

          setTitleFontFamily(fontFamily);
          setTitleColor(titleColor);
          setPriceColor(priceColor);
          setNameColor(nameColor);

          // Dynamically update footer and termsConditions font sizes
          setFooterFontSizes((prev) => ({
            ...prev,
            [activeTabLive]: footerFontSize || FooterSelectorfontSizes[0],
          }));
          setTcFontSizes((prev) => ({
            ...prev,
            [activeTabLive]:
              termsConditionsFontSize || FooterSelectorfontSizes[0],
          }));

          setFontSettings({
            _id,
          });

          // Show success toast notification if not already shown
          if (!successToastShown) {
            toast.success("Default settings applied successfully!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setSuccessToastShown(true);
          }
          setErrorToastShown(false);
        } else {
          if (!errorToastShown) {
            toast.error(
              "The tab from the response does not match the active tab.",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              }
            );
            setErrorToastShown(true);
          }
        }
      })
      .catch((error) => {
        setIsDataAvailable(false); // Set to false if there's an error

        if (!errorToastShown) {
          toast.error("Error applying default settings: " + error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setErrorToastShown(true);
        }
        setSuccessToastShown(false);
      });
  };

  const handleUpdateSettings = async () => {
    try {
      const { _id } = fontSettings;

      const selectedTitleFontSize = titleFontSizes[activeTabLive];
      const selectedNameFontSize = nameFontSizes[activeTabLive];
      const selectedPriceFontSize = priceFontSizes[activeTabLive];
      // const selectedFooterFontSize = footerFontSizes[activeTabLive];
      // const selectedTCFontSize = TcFontSizes[activeTabLive];

      const payload = {
        ...fontSettings,
        titleFontSize: selectedTitleFontSize,
        productionFontSize: selectedNameFontSize,
        productFontSize: selectedPriceFontSize,
        fontFamily: titleFontFamily,
        titleColor: titleColor,
        priceColor: priceColor,
        nameColor: nameColor,
        footerFontSize: footerFontSizes[activeTabLive],
        termsConditionsFontSize: TcFontSizes[activeTabLive],
      };

      // Send the payload to the API including tab and _id in the URL and headers
      const response = await axios.put(
        `${Update_Settings}/${activeTabLive}/${_id}`, // Add tab and _id to the URL
        payload
      );

      if (response.status === 200) {
        toast.success("Settings updated successfully.");
      }
    } catch (error) {
      toast.error("Error updating settings. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };
  const handleStoreSettings = async () => {
    try {
      const formData = {
        tab: activeTabLive,
        titleFontSize: titleFontSizes[activeTabLive],
        productionFontSize: nameFontSizes[activeTabLive],
        titleColor: titleColor,
        nameColor: nameColor,
        priceColor: priceColor || "#000000",
        productFontSize: priceFontSizes[activeTabLive],
        fontFamily: titleFontFamily,
        footerFontSize: footerFontSizes,
        termsConditionsFontSize: TcFontSizes,
      };
      // Make the POST request to the API
      const response = await axios.post(`${store_Settings}`, formData);
      toast.success("Settings Added", {
        autoClose: 3000, // Set duration to 3 seconds
      });
    } catch (error) {
      toast.error("Failed to update settings", {
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <div className="banner-generator-main">
        <div className="form-pnl">
          <div className="scrollable-form-container">
            {activeTab !== "pdfGeneration" && (
              <DropdownButton
                id="dropdown-basic-button"
                title={
                  <img className="ThreeDotimg" src={ThreeDot} alt="List" />
                }
                onToggle={(isOpened) => setIsOpened(isOpened)}
              >
                <Dropdown.Item
                  className="Default_settingsbtn"
                  onClick={handleApplyDefaultSettings}
                >
                  Apply Default Settings
                </Dropdown.Item>
                {isDataAvailable ? (
                  <Dropdown.Item
                    className="Updatebtn"
                    onClick={handleUpdateSettings}
                  >
                    Update Settings
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    className="Updatebtn"
                    onClick={handleStoreSettings}
                  >
                    Save Default Settings
                  </Dropdown.Item>
                )}
              </DropdownButton>
            )}
            <Tabs
              id="controlled-tab-example"
              activeKey={activeTab}
              onSelect={(k) => handleTabSelect(k)}
              className="mb-3 custom-tabs justify-content-center"
            >
              <Tab
                eventKey="livePreview"
                title="Banner Generation"
                onClick={() => handleTabSelect("livePreview")}
              >
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Upload Background Image:</Form.Label>

                    <Form.Control
                      className="reg-btn-notification big w-100 br-rad colorBorder"
                      type="file"
                      accept="image/*"
                      onChange={(e) => setBackground(e.target.files[0])} // Store the selected image file
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Font Family:</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={titleFontFamily}
                      onChange={(e) => setTitleFontFamily(e.target.value)}
                    >
                      {fontFamilies?.map((family) => (
                        <option key={family} value={family}>
                          {family}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Enter Title:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Title"
                      value={inputValue}
                      maxLength={100}
                      onChange={handleTitleChange}
                    />
                    {inputValue.length >= 100 && (
                      <Form.Text className="text-danger">
                        Maximum limit of 100 characters reached.
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Select Title Font Size:</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={
                        titleFontSizes[activeTabLive] ||
                        (activeTabLive && defaultFontSizes[activeTabLive])
                      }
                      onChange={handleFontSizeChange}
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <div>
                    <Form.Group className="mb-3">
                      <Form.Label>Select Title Color:</Form.Label>
                      <br />
                      <div className="Colorbtn">
                        <Button
                          className="colormapbtn"
                          onClick={() => {
                            setActiveTitlePicker((prev) =>
                              prev === "titleMap" ? "" : "titleMap"
                            );
                          }}
                        >
                          Standard Color
                        </Button>
                        <Button
                          className="colormapbtn"
                          onClick={() => setActiveTitlePicker("titleStandard")}
                        >
                          Custom Color
                        </Button>
                      </div>

                      {activeTitlePicker == "titleMap" && (
                        // <ColorMap
                        //   currentColor={titleColor}
                        //   setColor={handleTitleColorChange}
                        //   applyTo="titleMap" // This will update titleColor
                        // />

                        <ColorMapPicker
                          currentColor={titleColor}
                          setColor={handleTitleColorChange}
                          applyTo="titleMap"
                        />
                      )}

                      {activeTitlePicker == "titleStandard" && (
                        <Form.Control
                          type="color"
                          value={titleColor}
                          onChange={(e) =>
                            handleTitleColorChange(
                              e.target.value,
                              "titleStandard"
                            )
                          }
                          style={{ marginTop: "10px", width: "100%" }}
                        />
                      )}
                    </Form.Group>
                    {/* Text Effect Selector Section */}
                    <Form.Group className="mb-3">
                      <Form.Label>Add Text Effect:</Form.Label>
                      <div
                        className="custom-select-container"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <div className="custom-select">
                          {textEffect ? (
                            <div className="selected-effect">
                              <img
                                src={
                                  textEffects.find(
                                    (effect) => effect.value === textEffect
                                  )?.imgSrc
                                }
                                alt=""
                                className="effect-image"
                              />
                              <span>
                                {
                                  textEffects.find(
                                    (effect) => effect.value === textEffect
                                  )?.label
                                }
                              </span>
                            </div>
                          ) : (
                            <span>Select Effect</span>
                          )}
                        </div>

                        {isOpen && (
                          <div className="custom-dropdown">
                            <div className="dropdown-grid">
                              {textEffects?.map((effect) => (
                                <div
                                  key={effect.value}
                                  className={`dropdown-option ${
                                    effect.value === TextEffectTypes.GRADIENT
                                      ? "gradient-styling"
                                      : ""
                                  }`}
                                  onClick={() => handleSelectEffect(effect)}
                                >
                                  <img
                                    src={effect.imgSrc}
                                    alt={effect.label}
                                    className={`effect-image ${
                                      effect.value === TextEffectTypes.GRADIENT
                                        ? "gradient-image"
                                        : ""
                                    }`}
                                  />
                                  <span>{effect.label}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label>Product</Form.Label>
                    <Button
                      className="reg-btn mb-3 big block button-border"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Select Product
                    </Button>

                    <div className="mt-2">
                      {product && (
                        <Form.Control
                          type="text"
                          value={product}
                          readOnly
                          onClick={() => setIsModalOpen(true)}
                        />
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Product Name Font Size:</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={nameFontSizes[activeTabLive] || fontSizes[0]}
                      onChange={handleNameFontSizeChange}
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size} {/* Display the size */}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Name Color:</Form.Label>
                    <br />
                    <div className="Colorbtn">
                      <Button
                        className="colormapbtn"
                        onClick={() =>
                          setActiveNamePicker((prev) =>
                            prev === "nameMap" ? "" : "nameMap"
                          )
                        }
                      >
                        Standard Color
                      </Button>
                      <Button
                        className="colormapbtn"
                        onClick={() => setActiveNamePicker("nameStandard")}
                      >
                        Custom Color
                      </Button>
                    </div>

                    {activeNamePicker == "nameMap" && (
                      <ColorMapPicker
                        currentColor={nameColor}
                        setColor={handleNameColorChange}
                        applyTo="nameMap" // This will update nameColor
                      />
                    )}

                    {activeNamePicker == "nameStandard" && (
                      <Form.Control
                        type="color"
                        value={nameColor}
                        onChange={(e) =>
                          handleNameColorChange(e.target.value, "nameStandard")
                        }
                        style={{ marginTop: "10px", width: "100%" }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Price Font Size:</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={priceFontSizes[activeTabLive] || fontSizes[0]} // Use the active tab's price font size
                      onChange={handlePriceFontSizeChange} // Handle change for price font size
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size} {/* Display the size */}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Price Color:</Form.Label>
                    <br />
                    <div className="Colorbtn">
                      <Button
                        className="colormapbtn"
                        onClick={() =>
                          setActivePricePicker((prev) =>
                            prev === "priceMap" ? "" : "priceMap"
                          )
                        }
                      >
                        Standard Color
                      </Button>
                      <Button
                        className="colormapbtn"
                        onClick={() => setActivePricePicker("priceStandard")}
                      >
                        Custom Color
                      </Button>
                    </div>

                    {activePricePicker == "priceMap" && (
                      <ColorMapPicker
                        currentColor={FooterColor}
                        setColor={handlePriceColorChange}
                        applyTo="priceMap" // This will update priceColor
                      />
                    )}

                    {activePricePicker == "priceStandard" && (
                      <Form.Control
                        type="color"
                        value={FooterColor}
                        onChange={(e) =>
                          handlePriceColorChange(
                            e.target.value,
                            "priceStandard"
                          )
                        }
                        style={{ marginTop: "10px", width: "100%" }}
                      />
                    )}
                  </Form.Group>
                  <div>
                    {productDetails?.length > 0 &&
                      productDetails?.find(
                        (p) => p.name.toLowerCase() === product.toLowerCase()
                      ) && (
                        <>
                          {(() => {
                            const selectedProduct = productDetails?.find(
                              (p) =>
                                p.name.toLowerCase() === product.toLowerCase()
                            );
                            const adjustedPrice =
                              parseFloat(selectedProduct?.price) * 0.01; // Ensure it's a float

                            // Log the values for debugging purposes
                            return (
                              <>
                                <div>
                                  <Form.Label>Custom Price</Form.Label>
                                  {isEditing ? (
                                    <Form.Control
                                      type="number"
                                      value={customPrice}
                                      onChange={handlePriceChange}
                                      onBlur={handleBlur}
                                      className="mb-3"
                                      autoFocus
                                    />
                                  ) : (
                                    <Form.Control
                                      value={`$${
                                        customPrice
                                          ? parseFloat(customPrice).toFixed(2)
                                          : adjustedPrice.toFixed(2)
                                      }`}
                                      onClick={() => setIsEditing(true)}
                                      readOnly
                                    />
                                  )}
                                </div>
                              </>
                            );
                          })()}
                        </>
                      )}
                  </div>
                  {activeTabLive === "NFT Size" && (
                    <div>
                      <Form.Group className="mb-3">
                        <Form.Label>QR Generator:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter QR Text"
                          value={qrText}
                          onChange={(e) => setQrText(e.target.value)}
                        />
                        <Button
                          className="reg-btn mb-3 big block button-border"
                          onClick={handleGenerateQR}
                        >
                          Generate QR
                        </Button>
                      </Form.Group>
                    </div>
                  )}
                  {/* Footer Part  */}

                  <Form.Group className="mb-3">
                    {["checkbox"].map((type) => (
                      <div key={`default-${type}`} className="mb-3">
                        <Form.Check
                          type={type}
                          id={`default-${type}`}
                          label={`Show Footer Input`}
                          checked={showFooterInput}
                          onChange={(e) => setShowFooterInput(e.target.checked)}
                        />

                        {showFooterInput && (
                          <>
                            <div className="footer-container">
                              <Form.Label>Enter Footer:</Form.Label>
                              <Form.Control
                                type="text"
                                value={footer}
                                onChange={(e) => setFooter(e.target.value)}
                              />
                            </div>

                            <Form.Group
                              className="mb-3"
                              controlId="formFooterFontSize"
                            >
                              <Form.Label>Select Footer Font Size:</Form.Label>
                              <Form.Select
                                required
                                className="form-control"
                                aria-label="Default select example"
                                value={footerFontSizes[activeTabLive] || ""}
                                onChange={(e) =>
                                  setFooterFontSizes({
                                    ...footerFontSizes,
                                    [activeTabLive]: e.target.value,
                                  })
                                }
                              >
                                {(FooterSelectorfontSizes || []).map((size) => (
                                  <option key={size} value={size}>
                                    {size}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Select Footer Color:</Form.Label>
                              <br />
                              <div className="Colorbtn">
                                <Button
                                  className="colormapbtn"
                                  onClick={() =>
                                    setActiveFooterPicker((prev) =>
                                      prev === "priceMap" ? "" : "priceMap"
                                    )
                                  }
                                >
                                  Standard Color
                                </Button>
                                <Button
                                  className="colormapbtn"
                                  onClick={() =>
                                    setActiveFooterPicker("priceStandard")
                                  }
                                >
                                  Custom Color
                                </Button>
                              </div>

                              {activeFooterPicker == "priceMap" && (
                                <ColorMapPicker
                                  currentColor={FooterColor}
                                  setColor={handleFooterColorChange}
                                  applyTo="priceMap"
                                />
                              )}

                              {activeFooterPicker == "priceStandard" && (
                                <Form.Control
                                  type="color"
                                  value={FooterColor}
                                  onChange={(e) =>
                                    handleFooterColorChange(
                                      e.target.value,
                                      "priceStandard"
                                    )
                                  }
                                  style={{ marginTop: "10px", width: "100%" }}
                                />
                              )}
                            </Form.Group>
                          </>
                        )}

                        <Form.Group className="mb-3">
                          <Form.Check
                            type={type}
                            label="T & C"
                            id={`disabled-default-${type}`}
                            checked={showTC}
                            onChange={(e) => setShowTC(e.target.checked)}
                          />

                          {/* Conditionally render the size selector if the checkbox is checked */}
                          {showTC && (
                            <>
                              <Form.Label>Select T&C Font Size:</Form.Label>
                              <Form.Select
                                required
                                className="form-control"
                                aria-label="T&C font size"
                                value={TcFontSizes[activeTabLive] || ""}
                                onChange={(e) =>
                                  setTcFontSizes({
                                    ...TcFontSizes,
                                    [activeTabLive]: e.target.value,
                                  })
                                }
                              >
                                {(FooterSelectorfontSizes || []).map((size) => (
                                  <option key={size} value={size}>
                                    {size}
                                  </option>
                                ))}
                              </Form.Select>

                              <Form.Group className="mb-3">
                                <Form.Label>Select T & C Color:</Form.Label>
                                <br />
                                <div className="Colorbtn">
                                  <Button
                                    className="colormapbtn"
                                    onClick={() =>
                                      setActiveTCPicker((prev) =>
                                        prev === "priceMap" ? "" : "priceMap"
                                      )
                                    }
                                  >
                                    Standard Color
                                  </Button>
                                  <Button
                                    className="colormapbtn"
                                    onClick={() =>
                                      setActiveTCPicker("priceStandard")
                                    }
                                  >
                                    Custom Color
                                  </Button>
                                </div>

                                {activeTCPicker == "priceMap" && (
                                  <ColorMapPicker
                                    currentColor={showTcColor}
                                    setColor={handleTcColorChange}
                                    applyTo="priceMap"
                                  />
                                )}

                                {activeTCPicker == "priceStandard" && (
                                  <Form.Control
                                    type="color"
                                    value={showTcColor}
                                    onChange={(e) =>
                                      handleTcColorChange(
                                        e.target.value,
                                        "priceStandard"
                                      )
                                    }
                                    style={{ marginTop: "10px", width: "100%" }}
                                  />
                                )}
                              </Form.Group>
                            </>
                          )}
                        </Form.Group>
                      </div>
                    ))}
                  </Form.Group>
                </Form>
              </Tab>
              <Tab
                eventKey="pdfGeneration"
                title="Pdf Generation"
                onClick={() => handleTabSelect("pdfGeneration")}
              >
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Upload Background Image:</Form.Label>

                    <Form.Control
                      className="reg-btn-notification big w-100 br-rad colorBorder "
                      type="file"
                      accept="image/*"
                      onChange={(e) => setPdfBackground(e.target.files[0])}
                    />
                    <Form.Check
                      type="checkbox"
                      label={
                        <span onClick={(e) => e.preventDefault()}>
                          Use uploaded background image
                        </span>
                      }
                      checked={useUploadedBackground}
                      onChange={handleCheckboxChange}
                      className="mt-3"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Font Family:</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={pdfTitleFontFamily}
                      onChange={(e) => setPdfTitleFontFamily(e.target.value)}
                    >
                      {fontFamilies?.map((family) => (
                        <option key={family} value={family}>
                          {family}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Enter Title:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Title"
                      value={pdfTitle}
                      maxLength={100}
                      onChange={(e) =>
                        setPdfTitle(e.target.value.toUpperCase())
                      }
                    />
                    {pdfTitle.length >= 100 && (
                      <Form.Text className="text-danger">
                        Maximum limit of 100 characters reached.
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Select Title Font Size:</Form.Label>

                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={pdftitleFontSize}
                      onChange={(e) => setPdfTitleFontSize(e.target.value)}
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Title Color:</Form.Label>
                    <br />
                    <div className="Colorbtn">
                      <Button
                        className="colormapbtn"
                        onClick={() => {
                          setActiveTitlePickerPdf((prev) =>
                            prev === ColorPickerModes.STANDARD_COLOR
                              ? ColorPickerModes.NONE
                              : ColorPickerModes.STANDARD_COLOR
                          );
                        }}
                      >
                        Standard Color
                      </Button>

                      <Button
                        className="colormapbtn"
                        onClick={() => setActiveTitlePickerPdf("titleStandard")}
                      >
                        Custom Color
                      </Button>
                    </div>

                    {activeTitlePickerPdf === "titleMap" && (
                      <ColorMapPicker
                        currentColor={pdfTitleColor}
                        setColor={handleTitleColorChangePdf}
                        applyTo="titleMap" // This will update pdfTitleColor
                      />
                    )}

                    {activeTitlePickerPdf === "titleStandard" && (
                      <Form.Control
                        type="color"
                        value={pdfTitleColor}
                        onChange={(e) =>
                          handleTitleColorChangePdf(
                            e.target.value,
                            "titleStandard"
                          )
                        }
                        style={{ marginTop: "10px", width: "100%" }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Product</Form.Label>
                    <Button
                      className="reg-btn mb-3 big block button-border"
                      onClick={() => setPdfIsModalOpen(true)}
                    >
                      Select Product
                    </Button>

                    <div className="mt-2">
                      {pdfProduct && (
                        <Form.Control
                          type="text"
                          value={pdfProduct}
                          onClick={() => setPdfIsModalOpen(true)}
                        />
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Select Product Name Font Size:</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      aria-label="Default select example"
                      value={pdfnameFontSize}
                      onChange={(e) => setPdfNameFontSize(e.target.value)}
                    >
                      {fontSizes?.map((size) => (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Product Name Color:</Form.Label>
                    <br />
                    <div className="Colorbtn">
                      <Button
                        className="colormapbtn"
                        onClick={() => {
                          setActiveNamePickerPdf((prev) =>
                            prev === NamePickerModes.STANDARD_COLOR
                              ? NamePickerModes.NONE
                              : NamePickerModes.STANDARD_COLOR
                          );
                        }}
                      >
                        Standard Color
                      </Button>

                      <Button
                        className="colormapbtn"
                        onClick={() => setActiveNamePickerPdf("nameStandard")}
                      >
                        Custom Color
                      </Button>
                    </div>

                    {activeNamePickerPdf === "nameMap" && (
                      <ColorMapPicker
                        currentColor={pdfNameColor}
                        setColor={handleNameColorChangePdf}
                        applyTo="nameMap" // This will update pdfNameColor
                      />
                    )}

                    {activeNamePickerPdf === "nameStandard" && (
                      <Form.Control
                        type="color"
                        value={pdfNameColor}
                        onChange={(e) =>
                          handleNameColorChangePdf(
                            e.target.value,
                            "nameStandard"
                          )
                        }
                        style={{ marginTop: "10px", width: "100%" }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Price Color:</Form.Label>
                    <br />
                    <div className="Colorbtn">
                      <Button
                        className="colormapbtn"
                        onClick={() => {
                          setActivePricePickerPdf((prev) =>
                            prev === "priceMap" ? "" : "priceMap"
                          );
                        }}
                      >
                        Standard Color
                      </Button>
                      <Button
                        className="colormapbtn"
                        onClick={() => setActivePricePickerPdf("priceStandard")}
                      >
                        Custom Color
                      </Button>
                    </div>

                    {activePricePickerPdf === "priceMap" && (
                      <ColorMapPicker
                        currentColor={pdfPriceColor}
                        setColor={handlePriceColorChangePdf}
                        applyTo="priceMap" // This will update pdfPriceColor
                      />
                    )}

                    {activePricePickerPdf === "priceStandard" && (
                      <Form.Control
                        type="color"
                        value={pdfPriceColor}
                        onChange={(e) =>
                          handlePriceColorChangePdf(
                            e.target.value,
                            "priceStandard"
                          )
                        }
                        style={{ marginTop: "10px", width: "100%" }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Price Background Color:</Form.Label>
                    <br />
                    <div className="Colorbtn">
                      <Button
                        className="colormapbtn"
                        onClick={() => {
                          setActivePriceBgPickerPdf((prev) =>
                            prev === "priceBgMap" ? "" : "priceBgMap"
                          );
                        }}
                      >
                        Standard Color
                      </Button>
                      <Button
                        className="colormapbtn"
                        onClick={() =>
                          setActivePriceBgPickerPdf("priceBgStandard")
                        }
                      >
                        Custom Color
                      </Button>
                    </div>

                    {activePriceBgPickerPdf === "priceBgMap" && (
                      <ColorMapPicker
                        currentColor={starColor}
                        setColor={handleStarColorChangePdf}
                        applyTo="priceBgMap" // This will update the starColor (Price Background)
                      />
                    )}

                    {activePriceBgPickerPdf === "priceBgStandard" && (
                      <Form.Control
                        type="color"
                        value={starColor}
                        onChange={(e) =>
                          handleStarColorChangePdf(
                            e.target.value,
                            "priceBgStandard"
                          )
                        }
                        style={{ marginTop: "10px", width: "100%" }}
                      />
                    )}
                  </Form.Group>

                  <div>
                    {pdfProductDetails?.length > 0 &&
                      pdfProductDetails?.find(
                        (p) =>
                          p.name.toLowerCase() === pdfProduct?.toLowerCase()
                      ) && (
                        <>
                          {(() => {
                            const selectedPdfProduct = pdfProductDetails.find(
                              (p) =>
                                p.name.toLowerCase() ===
                                pdfProduct.toLowerCase()
                            );
                            const adjustedPrice =
                              selectedPdfProduct.price * 0.01; // Calculate the adjusted price

                            return (
                              <>
                                <div>
                                  <Form.Label>Custom Price</Form.Label>
                                  {isPdfEditing ? (
                                    <Form.Control
                                      type="number"
                                      value={pdfcustomPrice}
                                      onChange={handlePdfPriceChange}
                                      onBlur={handlePdfBlur}
                                      className="mb-3"
                                      autoFocus
                                    />
                                  ) : (
                                    <Form.Control
                                      value={`$${
                                        pdfcustomPrice
                                          ? pdfcustomPrice
                                          : adjustedPrice.toFixed(2)
                                      }`}
                                      onClick={() => setIsPdfEditing(true)}
                                    />
                                  )}
                                </div>
                              </>
                            );
                          })()}
                        </>
                      )}
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label className="Discription_">
                      Add Description:
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Your Discription"
                      value={description}
                      onChange={(e) => handleDescriptionChange(e)}
                      rows={1}
                      style={{ resize: "none", overflow: "hidden" }}
                    />
                    <small>{description?.length}/650 characters</small>
                  </Form.Group>

                  <div>
                    <Form.Label>Regular Price</Form.Label>
                    {isEditRegular ? (
                      <Form.Control
                        type="number"
                        value={regularPrice}
                        onChange={handleRegularPriceChange}
                        onBlur={handleRegularBlur}
                        className="mb-3"
                        autoFocus
                      />
                    ) : (
                      <Form.Control
                        value={regularPrice ? `$${regularPrice}` : ""}
                        onClick={() => setIsEditRegular(true)}
                        placeholder="Enter Regular Price"
                      />
                    )}
                  </div>

                  <Form.Group className="mb-3">
                    {["checkbox"].map((type) => (
                      <div key={`default-${type}`} className="mb-3">
                        <Form.Check
                          type={type}
                          id={`default-${type}`}
                          label={
                            <span onClick={(e) => e.preventDefault()}>
                              Show Footer Input
                            </span>
                          }
                          checked={showFooterInput}
                          onChange={(e) => setShowFooterInput(e.target.checked)}
                        />
                        {showFooterInput && (
                          <>
                            <div className="footer-container">
                              <Form.Label>Enter Footer:</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                value={pdfFooter}
                                onChange={(e) => setPdfFooter(e.target.value)}
                                maxLength={200}
                               
                              />
                            </div>
                            <Form.Group className="mb-3">
                              <Form.Label>Select Footer Color:</Form.Label>
                              <br />
                              <div className="Colorbtn">
                                <Button
                                  className="colormapbtn"
                                  onClick={() =>
                                    setActiveFooterPdfPicker((prev) =>
                                      prev === "priceMap" ? "" : "priceMap"
                                    )
                                  }
                                >
                                  Standard Color
                                </Button>
                                <Button
                                  className="colormapbtn"
                                  onClick={() =>
                                    setActiveFooterPdfPicker("priceStandard")
                                  }
                                >
                                  Custom Color
                                </Button>
                              </div>

                              {activeFooterPdfPicker == "priceMap" && (
                                <ColorMapPicker
                                  currentColor={showFooterPdf}
                                  setColor={handleFooterPdfColorChange}
                                  applyTo="priceMap"
                                />
                              )}

                              {activeFooterPdfPicker == "priceStandard" && (
                                <Form.Control
                                  type="color"
                                  value={showFooterPdf}
                                  onChange={(e) =>
                                    handleFooterPdfColorChange(
                                      e.target.value,
                                      "priceStandard"
                                    )
                                  }
                                  style={{ marginTop: "10px", width: "100%" }}
                                />
                              )}
                            </Form.Group>
                          </>
                        )}
                      </div>
                    ))}
                    <div>
                      <Form.Check
                        type="checkbox"
                        label={
                          <span onClick={(e) => e.preventDefault()}>
                            Add Soldout Tag
                          </span>
                        }
                        className="mt-3"
                        onChange={handleCheckSoldOut}
                      />
                    </div>
                  </Form.Group>
                </Form>
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="banner-pnl">
          {activeTab === "pdfGeneration" && (
            <PdfScreens
              activeTab={activeTab}
              pdfbackground={pdfbackground}
              title={pdfTitle}
              pdfProduct={pdfProduct}
              titleFontSize={pdftitleFontSize}
              size={size}
              isChecked={isChecked}
              showFooterPdf={showFooterPdf}
              useUploadedBackground={useUploadedBackground}
              footer={pdfFooter}
              titleColor={pdfTitleColor}
              starColor={starColor}
              nameColor={pdfNameColor}
              pdfPriceColor={pdfPriceColor}
              regularPrice={regularPrice}
              description={description}
              pdfTitleFontFamily={pdfTitleFontFamily}
              titleFontFamily={pdfTitleFontFamily}
              nameFontSize={pdfnameFontSize}
              priceFontSize={pdfPriceFontSize}
              pdfcustomPrice={pdfcustomPrice}
              pdfProductDetails={pdfProductDetails}
            />
          )}
          {activeTab === "livePreview" && (
            <LivePreview
              background={memoizedBackgroundUrl}
              title={memoizedTitle}
              product={product}
              size={size}
              textEffect={textEffect}
              useUploadedBackground={useUploadedBackground}
              footer={footer}
              generatedQR={generatedQR}
              showPreviews={showPreviews}
              titleColor={titleColor}
              nameColor={nameColor}
              FooterColor={FooterColor}
              showTcColor={showTcColor}
              priceColor={priceColor}
              qrText={qrText}
              TcFontSizes={TcFontSizes[activeTabLive] || []}
              footerFontSizes={footerFontSizes[activeTabLive] || []}
              regularPrice={regularPrice}
              discription={discription}
              titleFontSize={titleFontSizes[activeTabLive] || fontSizes[0]}
              nameFontSize={nameFontSizes[activeTabLive] || fontSizes[0]}
              priceFontSize={priceFontSizes[activeTabLive] || fontSizes[0]}
              titleFontFamily={titleFontFamily}
              showTC={showTC}
              customPrice={customPrice}
              productDetails={memoizedProductDetails}
            />
          )}
        </div>
      </div>

      {activeTab === "pdfGeneration" ? (
        <PdfModal
          isOpen={pdfIsModalOpen}
          onRequestClose={() => setPdfIsModalOpen(false)}
          onSelectProduct={handlePdfSelectProduct}
        />
      ) : (
        <ProductModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          onSelectProduct={handleSelectProduct}
        />
      )}
    </>
  );
};

export default BannerForm;

import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Button, Form, Table } from "react-bootstrap";
import CustomLoader from "../Components/CustomeLoader";
import { Pagination } from "react-pagination-bar";
import { handleActivityTracking } from "../shared/api";
import moment from "moment";
import { API_URLS, isValidRecipient } from "../constant";
import axios from "axios";
import { toast } from "react-toastify";
import PaginationComponent from "../pages/PaginationComponent";

const SendCoins = () => {
  const [loader, setloader] = useState(true);
  const [errorLogs, setErrorLogs] = useState([]);
  const [totalItemspage, setTotalItemspage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(""); // Single input for any search term
  const [recipient, setRecipient] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [noUserFoundMessage, setNoUserFoundMessage] = useState("");
  // Function to handle changes in the email input field
  const handleRecipientChange = (e) => {
    const recipient = e.target.value;
    setRecipient(recipient);

    // Only show an error if the input is not empty and it's invalid
    if (recipient && !isValidRecipient(recipient)) {
      setErrorMessage("Please enter a valid email, phone, or wallet address.");
    } else {
      setErrorMessage(""); // Clear error if recipient is valid or input is empty
    }
  };

  const handleSearch = async () => {
    setloader(true);
    setNoUserFoundMessage(""); // Reset the message before searching
    setErrorLogs([]); // Clear previous logs
    try {
      const response = await handleActivityTracking({
        currentPage,
        pageSize: 10,
        isTireBase: false,
        isMarketplace: false,
        isProductBase: false,
        Search: searchTerm.trim(),
      });
  
      if (response.isSuccess) {
        const items = response.data || [];
        setTotalItemspage(response.totalItems || 0);
        setErrorLogs(items);
      } else {
        console.error("API Error:", response.message || "Unknown error");
        setErrorLogs([{ description: response.message || "Unknown error" }]);
      }
    } catch (error) {
      console.error("Error fetching activity data:", error);
      setErrorLogs([{ description: "No User Found." }]);
    } finally {
      setloader(false);
    }
  };
  

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  useEffect(() => {
    handleSearch();
  }, [currentPage]);

  const handleSend = async (e) => {
    e.preventDefault();
    if (!recipient || !amount) {
      setErrorMessage("Both recipient and amount are required.");
      return;
    }
    if (amount <= 0) {
      setErrorMessage("Please enter a valid amount greater than 0.");
      return;
    }

    setErrorMessage("");
    setLoading(true);

    const payload = {
      recipient: recipient.trim(),
      tokenAmount: parseFloat(amount),
    };

    try {
      const token = localStorage.getItem("Token");
      if (!token) {
        setErrorMessage("Authorization token not found. Please log in.");
        setLoading(false);
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(API_URLS.SEND_TOKENS, payload, config);
      if (response.status === 200) {
        toast.success("Tokens sent successfully!");
        setRecipient("");
        setAmount("");
        window.location.reload();
      } else {
        setErrorMessage("Failed to send tokens. Please try again.");
      }
    } catch (error) {
      toast.error("No User Found");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />
      <CustomLoader isLoading={loading} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Send Coins</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="full-div">
              <div className="flex-div-sm">
                <h1>
                  Coin Transfer <span className="heading"></span>
                </h1>

                <ul className="btn-lister">
                  <li></li>
                </ul>
              </div>
            </div>

            <Form style={{ maxWidth: "900px" }}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Enter the recipient's email, phone, or wallet address to send
                  Coins:
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Recipient's email, phone, or wallet address"
                  className="form-control mt-3"
                  value={recipient}
                  onChange={handleRecipientChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value) && value >= 0) {
                      setAmount(value);
                      setErrorMessage("");
                    } else if (value < 0) {
                      setErrorMessage("Amount cannot be negative.");
                    }
                  }}
                  required
                  type="number"
                  value={amount}
                  placeholder="Enter amount"
                />
                {errorMessage && (
                  <p className="errormessage red mt-2">{errorMessage}</p>
                )}
              </Form.Group>

              <div className="spacer-40"></div>

              <ul className="half-list">
                <li>
                  {loader ? (
                    <Button className="reg-btn w-100 big">
                      <span
                        className="spinner-grow spinner-grow-sm"
                        id="loading-span"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      className="reg-btn w-100 big"
                      onClick={handleSend}
                    >
                      Send
                    </Button>
                  )}
                </li>
              </ul>
            </Form>

            <div className="full-div mt-4">
              <div className="flex-div-sm">
                <h1 className="Activityhistory">Activity History </h1>

                <ul className="btn-lister">
                  <li>
                    <div className="search-pnl">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSearch();
                        }}
                      >
                        <div>
                          <Form.Group controlId="formBasicEmail">
                            <div className="input-group">
                              <Form.Control
                                type="search"
                                placeholder="Enter Address, Email, Phone"
                                className="form-control"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                              <div className="input-group-append">
                                <Button
                                  className="reg-btn"
                                  type="button"
                                  onClick={handleSearch}
                                >
                                  <i className="fa fa-search"></i>
                                </Button>
                              </div>
                            </div>
                          </Form.Group>

                          {noUserFoundMessage && <p>{noUserFoundMessage}</p>}
                          {errorLogs.length > 0 && (
                            <ul>
                              {errorLogs.map((log, index) => (
                                <li key={index}>{log.name}</li> // Adjust based on your data structure
                              ))}
                            </ul>
                          )}
                        </div>
                      </Form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                <Table>
  <thead>
    <tr>
      <th>To</th>
      <th>Amount</th>
      <th>Timestamp</th>
      <th>From</th>
    </tr>
  </thead>
  {!loader ? (
  errorLogs.length > 0 ? (
    <tbody>
      {errorLogs.map((log, index) => (
        <tr key={index}>
          <td>{log.description || "N/A"}</td>
          <td>{log.amount || "N/A"}</td>
          <td>
            {log.createdAt
              ? moment(log.createdAt).format("YYYY-MM-DD HH:mm")
              : "N/A"}
          </td>
          <td>{log.senderEmail || "N/A"}</td>
        </tr>
      ))}
    </tbody>
  ) : (
    <tbody className="norecords">
      <tr>
        <td colSpan="4">{noUserFoundMessage || "No Record Found"}</td>
      </tr>
    </tbody>
  )
) : (
  <CustomLoader isLoading={loader} />
)}

</Table>

                </div>
              </div>

              <div className="full-div text-center pagination-container">
                <PaginationComponent
                  totalItems={totalItemspage}
                  itemsPerPage={10}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                    handleSearch();
                  }}
                  customClassNames={{
                    rpbItemClassName: "custom-item",
                    rpbItemClassNameActive: "custom-item--active",
                    rpbGoItemClassName: "custom-go-item",
                    rpbItemClassNameDisable: "custom-item--disable",
                    rpbProgressClassName: "custom-progress-bar",
                    rpbRootClassName: "custom-root",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SendCoins;

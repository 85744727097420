import React from "react";

const ImageModal = ({ modalImage, isModalOpen, closeModal }) => {
  if (!isModalOpen) return null;

  return (
    <div
      className="modal fade show"
      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}
      role="dialog"
      aria-hidden="true"
      onClick={closeModal}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        onClick={(e) => e.stopPropagation()} 
      >
        <div className="modal-content">
          <div className="modal-body p-0">
            <img
              src={modalImage}
              alt="No Img Available"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Breadcrumb, Button } from 'react-bootstrap';
import Sidebar from '../Components/Sidebar';
import Header from '../Components/header';
import { API_URL, Node_BE_URL, customStyles } from '../constant';
import axios from 'axios';
import axiosApi from '../axiosApi';
import { toast } from 'react-toastify';
import CheckFileSize from './controllers';
import CustomLoader from '../Components/CustomeLoader';
import Select from 'react-select';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Compressor from 'compressorjs';



function PushNotification() {
  const [loader, setloader] = useState(true);
  const [uploadImage, setuploadImage] = useState(null);
  const [allLocations, setAllLocations] = useState(null);
  const [SelectedConmpany, setSelectedConmpany] = useState(null);
  const CompaniesLocation = [{
    id:3854 ,
    name:'New Brunswick'
  },{
    id:1154,
    name:"Ontario"
  },{
    id:3501,
    name:'British Columbia'
  },{
    id:3515,
    name:'Saskatchewan'
  },{
    id:3643,
    name:'Albert'
  }]
  // const CompaniesLocation = [{
  //   id:448 ,
  //   name:'Staging Company'
  // }]
  const [notificationData, setnotificationData] = useState({
    title: '',
    image: null,
    description: '',
    Url:'',
    isChecked: false 
  });
  const [erroMessage, seterroMessage] = useState({
    title: null,
    image: null,
    description: null,
    store: null,
    Url:null,
  });
  const [stores, setStores] = useState([]);

  const GetAllLocations = () => {
    axios.get(`${Node_BE_URL}/api/admin/getAllLocations`).then((data) => {
      setAllLocations(data);
      setloader(false);
    }).catch((err) => { });
  }

  useEffect(() => {
    GetAllLocations();
  }, []);

  const navigate = useNavigate();

  const [uploadImages, setuploadImages] = useState({
    image: null,
  });
  const [imagePreview, setImagePreview] = useState(null);

  const handleFeaturedImageUpload = (event) => {
    const file = event.target.files[0];



    if (!file?.type.match(/^image\/(png|jpeg)$/)) {
      seterroMessage((prevState) => ({
        ...prevState,
        image: "Please upload a PNG or JPG image",
      }));
      setImagePreview(null);
      return;
    } else {
      if (CheckFileSize(file.size)) {
        return;
      }
      setnotificationData((prevState) => ({
        ...prevState,
        image: file,
      }));
      setuploadImages((prev) => ({
        ...prev,
        image: file,
      }));
      seterroMessage((prevState) => ({
        ...prevState,
        image: null,
      }));

      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };
    /**
   * Compresses and resizes the given image file.
   * Uses the Compressor.js library to reduce the image file size and dimensions.
   * 
   * @param {File} imageFile - The image file to be compressed and resized.
   * @returns {Promise<File>} - A promise that resolves to the compressed image file.
   * The compressed file will have a maximum width of 1280 pixels and a height of 720 pixels,
   * with a quality setting of 0.6. The function will ensure the compressed image size
   * does not exceed 300KB, rejecting the promise if it does.
   */
  const compressAndResizeImage = (imageFile) => {
    setloader(true); // Set loader to true when starting compression
    return new Promise((resolve, reject) => {
      new Compressor(imageFile, {
        quality: 0.6, // Set quality for compression
        maxWidth: 1280, // Maximum width
        maxHeight: 720, // Maximum height
        mimeType: 'image/jpeg', // Change to JPEG format
        success(result) {
          const compressedImageSize = result.size / 1024; // Size in KB
  
          if (compressedImageSize <= 300) { // Check size limit
            // Convert Blob to File
            const newFile = new File([result], imageFile.name, {
              type: result.type,
              lastModified: Date.now(),
            });
            resolve(newFile); // Resolve with the File object
          } else {
            setloader(false); // Reset loader if size limit exceeded

            reject("Compressed image is still larger than 300KB.");
          }
        },
        error(err) {
          reject(err);
          setloader(false); // Reset loader if size limit exceeded

        }
      });
    });
  };
  
  const handlePushNotification = async(e) => {
    e.preventDefault();


    if (!notificationData.title) {
      seterroMessage((prevState) => ({
        ...prevState,
        title: "Add notification title",
      }));
      setloader(false);
      return;
    } else {
      seterroMessage((prevState) => ({
        ...prevState,
        title: null,
      }));
    }
    



    // if (!notificationData.Url) {
    //   seterroMessage((prevState) => ({
    //     ...prevState,
    //     Url: "Add notification Url",
    //   }));
    //   setloader(false);
    //   return;
    // } else {
    //   seterroMessage((prevState) => ({
    //     ...prevState,
    //     Url: null,
    //   }));
    // }
    

   
    if ((!stores?.length && !SelectedConmpany) || (stores?.length && SelectedConmpany) ) {
      seterroMessage((prevState) => ({
        ...prevState,
        store: "please select either a store or a company, but not both",
      }));
      setloader(false);
      return;
    } else {
      seterroMessage((prevState) => ({
        ...prevState,
        store: null,
      }));
    }
    
    
    if (!notificationData.image) {
      seterroMessage((prevState) => ({
        ...prevState,
        image: "Add notification Image",
      }));
      setloader(false);
      return;
    } else {
      seterroMessage((prevState) => ({
        ...prevState,
        image: null,
      }));
    }
    const comparessfile = await compressAndResizeImage(notificationData.image)
    const bodyFormdata = new FormData();
    bodyFormdata.append("Tittle", notificationData.title);
    bodyFormdata.append("Description", notificationData.description);
    bodyFormdata.append("FullURL", notificationData.Url);

    // bodyFormdata.append("CommpanyId", '');
    bodyFormdata.append("Image", comparessfile);



    if (stores.length > 0) {
      stores.forEach(store => {
        bodyFormdata.append("PrefferedStoreId", store.value);
      });
    }
  
    if (SelectedConmpany) {
      bodyFormdata.append("CommpanyId", SelectedConmpany);
    }
  

    setloader(true);
    axios.post(API_URL + `api/Nft/SendNotification`, bodyFormdata, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Token")}`,
      },
    }).then((res) => {
      toast.success("Notification Sent Successfully");
      setloader(false);
      setnotificationData({
        title: '',
        image: null,
        description: '',
        Url:'',
        isChecked: false,
      });
      setStores([])
      setuploadImages({ image: null });
      setImagePreview(null);
      document.getElementById("notificationImage").value = "";
      navigate("/SendNotification");
    }).catch((error) => {
      setloader(false);
      toast.error("Something went wrong!");
      navigate("/SendNotification");
    });
  }
  

  const handleSelectChange = (selectedOptions) => {
    seterroMessage((prevState) => ({
      ...prevState,
      store: "",
    }));
    setStores(selectedOptions || []);
  };

  const options = allLocations?.data?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  
  
  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />
      <main className='dasboard-main'>
        <div className='dashboard-inner-pnl'>
          <Header />
          <div className='head-inner-panel'>
            <div className='full-div'>
              <Breadcrumb>
                <Breadcrumb.Item>Admin Management</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Send Push Notification</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className='full-div'>
              <h1>Send Notification</h1>
              <div className='spacer-20'></div>
            </div>
            <div className='full-div'>
              <Form onSubmit={handlePushNotification} style={{ maxWidth: '900px' }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Notification Title</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setnotificationData((prevState) => ({
                        ...prevState,
                        title: e.target.value,
                      }));
                    }}
                    type="text"
                    placeholder="Enter Notification Title"
                    value={notificationData.title}
                  />
                  {erroMessage.title && (
                    <p className="error-msg">{erroMessage.title}</p>
                  )}
                </Form.Group>
                
 
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Preferred Store</Form.Label>
          

    <ReactMultiSelectCheckboxes
  options={options}
  value={stores}
  onChange={handleSelectChange}
  labelledBy="Select Stores"
  className="multi-select"
  styles={customStyles} 
  placeholder="Stores selected" 
  

/>


                  {erroMessage.store && (
                    <p className="error-msg mt-2">{erroMessage.store}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Preferred Province</Form.Label>
                  <Form.Select
                    className="form-control"
                    aria-label="Default select example"
                    onChange={(e) => {
                      seterroMessage((prevState) => ({
                        ...prevState,
                        store: "",
                      }));
                      setSelectedConmpany(e.target.value === "" ? null : e.target.value);
                    }}
                  >
                    <option value={""}> Select Company </option>
                    {CompaniesLocation?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}-{item.id}
                      </option>
                    ))}
                  </Form.Select>
                  {erroMessage.store && (
                    <p className="error-msg">{erroMessage.store}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Notification Url</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      setnotificationData((prevState) => ({
                        ...prevState,
                        Url: e.target.value,
                      }));
                    }}
                    type="text"
                    placeholder="Enter Notification Url"
                    value={notificationData.Url}
                  />
                  {erroMessage.Url && (
                    <p className="error-msg">{erroMessage.Url}</p>
                  )}
      
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Notification Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter Notification Description"
                    onChange={(e) => {
                      setnotificationData((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                      }));
                    }}
                    value={notificationData.description}
                  />
                  {erroMessage.description && (
                    <p className="error-msg">{erroMessage.description}</p>
                  )}
                  
                </Form.Group>
                

                <Form.Group className="mb-3" controlId="notificationImage">
                  <Form.Label>Notification Image</Form.Label>
                  <Form.Control
                    type="file"
                    className="reg-btn-notification big w-100 br-rad"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleFeaturedImageUpload}
                  />
                  {erroMessage.image && (
                    <p className="error-msg">{erroMessage.image}</p>
                  )}
                </Form.Group>
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Image Preview"
                    className="uploaded-image-preview"
                    
                  />
                )}
                <div className='spacer-40'></div>

                <ul className='half-list'>
                  <li>
                    {loader ? (
                      <Button className='reg-btn w-100 big'>
                        <span className="spinner-grow spinner-grow-sm" id="loading-span" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                      </Button>
                    ) : (
                      <Button type='submit' className='reg-btn w-100 big'>
                        Send
                      </Button>
                    )}
                  </li>
                  <li>
                    <Button onClick={() => navigate(-1)} className='reg-btn w-100 trans big'>Cancel</Button>
                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default PushNotification;
